/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import api from 'api';
import moment from 'moment';
import {isInProgressEvent} from 'utils';
import {EventPhase, EventStatus} from './events.types';

/**
 *
 */
export function getEventStatus(event: api.Events.EventData): EventStatus {
  const phase = getEventPhase(event);

  if (event.forgiveness_applied) return EventStatus.FORGIVEN;

  switch (phase) {
    case EventPhase.PRE_EVENT:
      if (!event.participating) return EventStatus.PREPARING_OPTED_OUT;
      return EventStatus.PREPARING;

    case EventPhase.IN_PROGRESS:
      if (!event.participating) return EventStatus.OPTED_OUT;
      return EventStatus.PARTICIPATING;

    case EventPhase.POST_EVENT:
      // it's a failure when the `isFailure` key exists and it's true
      const isFailure =
        event?.ohm_plus_event_summary?.isFailure !== undefined &&
        event.ohm_plus_event_summary.isFailure;

      // we're awaiting result when there is no summary or the summary does not contain an isFailure key
      const isAwaitingResult =
        !event?.ohm_plus_event_summary || event?.ohm_plus_event_summary?.isFailure === undefined;

      if (isAwaitingResult) {
        return EventStatus.AWAITING_RESULT;
      } else if (!event.devices || isFailure) {
        if (!event.participating) return EventStatus.FAILURE_OPTED_OUT;
        return EventStatus.FAILURE;
      } else {
        return EventStatus.SUCCESS;
      }

    default:
      return EventStatus.SUCCESS;
  }
}

/**
 *
 */
export function getEventPhase(event: api.Events.EventData | api.Events.UpcomingEvent): EventPhase {
  const isInProgress = isInProgressEvent(event);
  const isPlanned = moment() < moment(event.start_dttm);

  if (isInProgress) return EventPhase.IN_PROGRESS;
  else if (isPlanned) return EventPhase.PRE_EVENT;
  else return EventPhase.POST_EVENT;
}

/**
 *
 */
export function getEventOptInOutAllowed(
  event: api.Events.EventData | api.Events.UpcomingEvent,
): boolean {
  // can be opted in/out of while in the PRE_EVENT phase
  const phase = getEventPhase(event);

  return phase === EventPhase.PRE_EVENT;
}
