/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import PopupAnnouncement from 'components/shared/PopupAnnouncement';
import {WithComponentPropsWithoutRef} from 'types';
import {formatForCurrency, trackStructuredEvent} from 'utils';
import {useHighestPayoutRateConfig} from 'store/ohmPlus';
import {PayoutDisplay, PayoutTable} from 'components/DeviceRequirements';
import {useGlobalState} from 'store';
import {useSetUserAttributes} from 'store/actions';
import styled, {css} from 'styled-components';

const StyledPayout = styled(PayoutDisplay)`
  ${({theme, payout}) => css`
    color: ${payout.value <= 0 ? theme.color.secondary100 : 'inherit'};
    background-color: unset;
  `}
`;

const StyledPayoutWrapper = styled.div`
  font-size: 19px;
  font-weight: bold;
`;

const AdditionalPayoutText = styled.p`
  margin: 4px 0;
`;

export default function DevicePayoutTableBillboard({
  ...props
}: WithComponentPropsWithoutRef<typeof PopupAnnouncement>) {
  const globalState = useGlobalState();
  const setUserAttributes = useSetUserAttributes();
  const rateConfigs = globalState?.plus?.rateConfigs ?? [];
  const currentPayout = globalState.plus?.status?.currentPayout;
  const ATTRIBUTE_KEY = 'BILLBOARD_DEVICE_PAYOUT_TABLE_COLLAPSED';
  const isCollapsed: boolean = globalState?.user?.attributes[ATTRIBUTE_KEY] === '1';

  async function handleOnCollapse(isNowCollapsed: boolean) {
    if (!isNowCollapsed) {
      trackStructuredEvent({
        category: 'billboard',
        action: 'view',
        label: 'payout_table',
      });
    }
    await setUserAttributes({[ATTRIBUTE_KEY]: isNowCollapsed ? '1' : '0'});
  }

  const highestPayoutRateConfig = useHighestPayoutRateConfig();
  const maxPlusRate = highestPayoutRateConfig
    ? formatForCurrency(
        highestPayoutRateConfig.payout.value,
        highestPayoutRateConfig.payout.currencyType,
        {
          notation: 'compact',
        },
      )
    : undefined;

  if (!rateConfigs.length) return null;

  const showAdditionalInfo =
    !!currentPayout &&
    (!highestPayoutRateConfig || highestPayoutRateConfig.payout.value > currentPayout.value);

  const currentRateAndCta: JSX.Element | null = !!currentPayout ? (
    <>
      <StyledPayoutWrapper>
        {"You're getting"}
        <StyledPayout payout={currentPayout} suffix="/month" />
      </StyledPayoutWrapper>
      {showAdditionalInfo ? (
        <AdditionalPayoutText>
          Earn {maxPlusRate ? `up to ${maxPlusRate}/month ` : 'each month '}by linking devices
        </AdditionalPayoutText>
      ) : null}
    </>
  ) : null;

  return (
    <PopupAnnouncement
      {...props}
      options={{
        header: '',
        message: (
          <>
            <PayoutTable padding={0} {...{rateConfigs}} />
            <br />
            {currentRateAndCta}
          </>
        ),
        includeIcon: false,
        dismissable: false,
        collapsable: true,
        isCollapsed: isCollapsed,
        collapseLabel: 'Hide Payouts',
        uncollapseLabel: 'Show Payouts',
        onCollapse: handleOnCollapse,
        align: 'center',
        ctaDirection: 'column',
        ctas: [],
      }}
    />
  );
}
