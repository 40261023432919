/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';

/* eslint-disable camelcase */
export namespace Delighted {
  /**
   * Get user properties
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getUserProperties<TResponse = UserProperties, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/delighted/user_properties`, config);
  }

  /**
   * Get current active milestone for user
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getActiveMilestone<TResponse = MilestoneData | null, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/delighted/milestones/active`, config);
  }

  /**
   * mark milestone survey as triggered (requested from Delighted)
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function markMilestoneSurveyTriggered<
    TResponse = MarkSurveyResponse,
    TConfigData = never
  >(
    milestoneId: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(
      `/api/v2/delighted/milestones/${milestoneId}/mark_survey_triggered`,
      undefined,
      config,
    );
  }

  /**
   * mark milestone survey as shown
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function markMilestoneSurveyShown<
    TResponse = MarkSurveyResponse,
    TConfigData = never
  >(
    milestoneId: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(
      `/api/v2/delighted/milestones/${milestoneId}/mark_survey_shown`,
      undefined,
      config,
    );
  }

  /**
   * mark milestone survey as responded to
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function markMilestoneSurveyResponded<
    TResponse = MarkSurveyResponse,
    TConfigData = never
  >(
    milestoneId: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(
      `/api/v2/delighted/milestones/${milestoneId}/mark_survey_responded`,
      undefined,
      config,
    );
  }

  /**
   * mark milestone survey as dismissed
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function markMilestoneSurveyDismissed<
    TResponse = MarkSurveyResponse,
    TConfigData = never
  >(
    milestoneId: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(
      `/api/v2/delighted/milestones/${milestoneId}/mark_survey_dismissed`,
      undefined,
      config,
    );
  }

  export interface MilestoneData {
    milestone: Milestone;
    user_properties: UserProperties;
  }

  /**
   *
   */
  export interface Milestone {
    user_delighted_milestone_id: number;
    delighted_milestone_id: number;
    cached_delighted_project: string;
    cached_delighted_milestone_name: string;
    cached_delighted_milestone_type: string;
    cached_delighted_milestone_ordinal: number;
    cached_delighted_milestone_tier: string;
  }

  /**
   *
   */
  export interface UserProperties {
    city: string;
    countyStr: string;
    enrollDetailsStr: string;
    enrollState: string;
    enrollSubstate: string;
    state: string;
    userId: number;
    utility: string;
    zip: string;
    zipcodeIsHighBaseline: string;
    baseline: string;
    currentStreak: number;
    energySaved: number;
    wasMigratedFromLegacyBool: boolean;
    inSPABool: boolean;
    hasEmsExperience: boolean;
    longestStreak: number;
    referralsActiveNum: number;
    signupDate: string;
    tier: string;
  }

  /**
   *
   */
  export interface MarkSurveyResponse {
    error?: string;
    status?: string;
  }
}
