/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React, {Suspense, lazy} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {ApolloProvider} from '@apollo/client';

// This import is needed to execute initial configuration, see config/index.js
import 'config';

import {Store} from 'store';
import {lazyRetry, trackLinkClick} from 'utils';
import {CurrentTheme} from 'theme';
import GetUserAuthorization from 'components/auth/GetUserAuthorization';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import {ohmApolloClient} from 'graphql/config/apollo';

// Styles
import {GlobalStyles} from 'components/shared/styled';

import DisenrollmentRequest from 'components/DisenrollmentRequest';
import Maintenance from 'components/Maintenance';
import LoadingSpinner from 'components/shared/svgs/LoadingSpinner';
import useMountEffect from 'hooks/useMountEffect';
import {useExperimentExposed} from 'hooks/useExperiment';
import {ScrollToTopOnNavigate} from './components/shared/ScrollToTopOnNavigate';

// Dynamic imports enable code-splitting, in this case based on route
const ExternalApp = lazy(() => lazyRetry(() => import('components/ExternalApp'), 'externalApp'));
const Internal = lazy(() => lazyRetry(() => import('components/internal/Internal'), 'internalApp'));

// These tags will be automatically tracked
const CLICK_TRACK_TAGS = ['a', 'button'];

export const App = () => {
  const devicePageSpeedBumpTestExposed = useExperimentExposed()('device_page_speed_bump_test');

  // Automatically track clicks on any elements that either:
  // 1. Are one of the element types defined in CLICK_TRACK_TAGS
  // 2. Have a `data-clicktrackid` attribute. This could either be a value that will be stored or it
  //    could just be "true" e.g. <Icon data-clicktrackid> or <Icon data-clicktrackid='identifier'>.
  //    Note that this clicktrackid will be stored as the structured event action
  //    The `data-clicktracklabel` attribute can also be used, but only shows up in snowplow
  //    if there is no href element.
  // Note that we have to go looking "up" the DOM hierarchy since the target of the click could be
  // a span or SVG *within* an <a> tag.
  useMountEffect(() => {
    // KB: using explicit any here because this code is squirrely and not easy to type, but known working
    function clickTrackHandler(event) {
      // Use setTimeout to not slow down click handling: `[Violation] 'click' handler took 152ms`
      setTimeout(() => {
        let el = event.target;
        let isTrackableElement =
          (el.dataset && el.dataset.clicktrackid) ||
          CLICK_TRACK_TAGS.includes(event.target.nodeName.toLowerCase());
        // Look for a trackable element in the DOM hierarchy. For example, if the click was targeted
        // on an SVG but its parent element is a button or link.
        if (!isTrackableElement) {
          const parentTrackableElement =
            event.path &&
            event.path.find(
              // KB: using explicit any here because this is known to be working
              e =>
                (e.dataset && e.dataset.clicktrackid) ||
                CLICK_TRACK_TAGS.includes(e.nodeName && e.nodeName.toLowerCase()),
            );
          if (parentTrackableElement) {
            el = parentTrackableElement;
            isTrackableElement = true;
          }
        }
        if (isTrackableElement) {
          const ctLabel = el.dataset ? el.dataset.clicktracklabel : null;
          trackLinkClick(el.href || ctLabel || null, el.dataset.clicktrackid, el.innerText);
        }
      }, 0);
    }

    document.getElementsByTagName('body')[0].addEventListener('click', clickTrackHandler);
  });

  return (
    <ApolloProvider client={ohmApolloClient}>
      <Store>
        <BrowserRouter
          {...(devicePageSpeedBumpTestExposed
            ? {
                getUserConfirmation: () => {
                  /* Empty callback to block the default browser prompt */
                },
              }
            : {})}
        >
          <CurrentTheme>
            <ScrollToTopOnNavigate />
            <GlobalStyles />
            <ErrorBoundary>
              <GetUserAuthorization />
              <Suspense fallback={<LoadingSpinner id="AppFallback" />}>
                <Routes>
                  <Route path="/internal/*" element={<Internal />} />
                  <Route path="/disenroll" element={<DisenrollmentRequest />} />
                  <Route path="/maintenance" element={<Maintenance />} />
                  <Route path="/*" element={<ExternalApp />} />
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </CurrentTheme>
        </BrowserRouter>
      </Store>
    </ApolloProvider>
  );
};

export default App;
