/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
/* eslint-disable camelcase */
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';

// I would just like to point out that this does not match our existing pattern where
// the filename here reflects the file name in the python pages/api/v2 folder!

//  This file lines up with cashout.py in the backend
export namespace Wallet {
  /**********
   * Interactions
   **********/

  /**
   * Get wallet
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getWallet<TResponse = WalletData, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/wallet`, config);
  }

  /**
   * Transfer cash balance to PayPal
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function transferCashToPaypal<
    TResponse = CashToPaypalConfirmation,
    TConfigData = never
  >(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/cashout/cash/paypal`, undefined, config);
  }

  /**
   * Transfer cash balance to eGifts
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function transferCashToEGift<
    TResponse = CashToEGiftConfirmation,
    TConfigData = {id: string}
  >(
    data: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`api/v2/cashout/cash/egift`, data, config);
  }

  /**
   * Get the EGifts available for redemption
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getAvailableEGifts<TResponse = UserPossibleEGift[], TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/cashout/egifts`, config);
  }

  /**
   * Get the users gift codes
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getGiftCodes<TResponse = GiftCodesData, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/gift_codes`, config);
  }

  /**********
   * Types
   **********/

  /**
   *
   */
  export interface WalletData {
    cash_blockers: Blockers[];
    points_blockers: Blockers[];
    cashBalance: CashBalance;
    currentPoints: PointsRecord;
    deviceRewardsPrice: DeviceRewardsPrice;
    lastCashout: string | null;
    lifetimePoints: PointsRecord;
    max: CashoutLimits;
    min: CashoutLimits;
    prizeEntries: PrizeEntries;
    reward_marketplace_redeemable_spins: RewardMarketplaceRedeemableSpin[];
    reward_marketplace_wheel_prizes: Record<
      RewardMarketPlaceWheelPrizeType,
      RewardMarketplaceWheelPrize[]
    >;
    /** @deprecated this uses different logic from what is used to display gift cards */
    hasGiftCards: boolean;
  }

  /**
   *
   */
  export interface CashToPaypalConfirmation {
    requires_manual_approval: boolean;
    message: string;
  }

  /**
   *
   */
  export interface CashToEGiftConfirmation {
    requires_manual_approval: boolean;
    message: string;
  }

  /**
   *
   */
  export type Blockers = PaypalAccountBlockers | CashoutDisabledBlockers | TimeAndBalanceBlockers;

  /**
   *
   */
  export enum PaypalAccountBlockers {
    PAYPAL_MISSING_ADDRESS = 'paypal_missing_address',
    PAYPAL_VERIFIED_ADDRESS = 'paypal_verified_address',
  }

  /**
   *
   */
  export enum CashoutDisabledBlockers {
    CASHOUT_DISABLED = 'cashout_disabled',
    PAYPAL_DISABLED = 'cashout_disabled_paypal',
    TARGETED_BLOCK = 'targeted_block',
    UTILITY_DISABLED_PGE = 'cashout_disabled_utility_pge',
    UTILITY_DISABLED_SCE = 'cashout_disabled_utility_sce',
    UTILITY_DISABLED_SDGE = 'cashout_disabled_utility_sdge',
    UTILITY_DISABLED_THESL = 'cashout_disabled_utility_thesl',
  }

  /**
   *
   */
  export enum TimeAndBalanceBlockers {
    MINIMUM = 'minimum',
    WEEKLY_LIMIT = 'weekly_cashout_limit',
    MONTHLY_LIMIT = 'monthly_cashout_limit',
  }

  /**
   *
   */
  export interface CashBalance {
    cents: number;
    display_dollars: string;
  }

  /**
   *
   */
  export interface PointsRecord extends DollarsAndPoints {
    storeValue: number;
  }

  /**
   *
   */
  export interface DeviceRewardsPrice extends Record<string | number, any> {
    // TODO: type this
  }

  /**
   *
   */
  export interface CashoutLimits {
    giftcard: DollarsAndPoints;
    store: DollarsAndPoints;
  }

  /**
   *
   */
  export interface DollarsAndPoints {
    dollars: number;
    points: number;
  }

  /**
   *
   */
  export interface PrizeEntries extends Record<string | number, any> {
    // TODO: type this
  }

  export interface RewardMarketplaceRedeemableSpin {
    rmw_redeemable_spin_id: number;
    rmw_spin_id: number | null;
  }

  export enum RewardMarketPlaceWheelPrizeType {
    FREE_SPIN = 'FREE_SPIN',
    PRIZE_ENTRY = 'PRIZE_ENTRY',
    SHOPIFY_DISCOUNT = 'SHOPIFY_DISCOUNT',
    FREE_DEVICE = 'FREE_DEVICE',
    CASH = 'CASH',
    REFERRAL_BOOST = 'REFERRAL_BOOST',
    DAILY_CASH = 'DAILY_CASH',
    DAILY_CASH_INELIGIBLE = 'DAILY_CASH_INELIGIBLE',
  }

  /**
   *
   */
  export interface RewardMarketplaceWheelPrize {
    rmw_spin_id: number;
    rmw_prize_type: RewardMarketPlaceWheelPrizeType;
    rmw_prize_expires_dttm: string;
    rmw_prize_quantity: number;
    rmw_prize_executed_quantity: number;
  }

  /**
   * The user's possibility of redeeming for an EGift
   */
  export interface UserPossibleEGift {
    /** the EGift in question */
    eGift: EGift;
    /** the determination of whether the current cash balance can be used to purchase */
    validDenominationResult: ValidDenominationResult;
    /** the minimum amount that this user is allowed to cash out */
    minimumCashoutDollars: number;
  }

  export enum HawkBarcodeFormat {
    CA128 = '1D-CA128',
    PDF417 = '2D-PDF417',
    QRCODE = '2D-QRCODE',
  }

  /**
   * The prototypical information about a single EGift (not yet redeemed)
   */
  export type EGift = {
    id: number;
    /**
     * this should probably never be null, but the HawkMarketplace API doesn't seem to guarantee it
     */
    parentBrandName: string | null;
    productName: string | null;
    description: string | null;
    /**
     * again, probably never null, but not guaranteed by HawkMarketplace
     */
    productImageUrl: string | null;
    termsAndConditionsText: string | null;
    termsAndConditionsUrl: string | null;
    valueRestrictions: {
      /** maximum denomination allowed to purchase */
      maximum: number;
      /** minimum denomination allowed to purchase */
      minimum: number;
      /** array of exclusively allowed purchase denominations */
      exclusivelyAllowedValues: number[] | null;
    };
    barcodeFormat: HawkBarcodeFormat | null;
    barcodeScannable: boolean | null;
  };

  /**
   * The determination of whether the current cash balance can be used to purchase
   */
  export enum ValidDenominationResult {
    /** Denomination is too low (doesn't meet min requirement) */
    TooLow = 'TooLow',
    /** Denomination is acceptable */
    OK = 'OK',
    /** Denomination is too high (doesn't meet max requirement) */
    TooHigh = 'TooHigh',
    /** Returned when the requested denomination is not one of the exclusively
     *  allowed ones, but still within minimum and maximum bounds */
    RestrictedDenomination = 'RestrictedDenomination',
  }

  export enum GiftCardOrderFulfillmentService {
    OMNICARD = 'OMNICARD',
    AMAZON_INCENTIVES = 'AMAZON_INCENTIVES',
    PREZZEE = 'PREZZEE',
    HAWK_MARKETPLACE = 'HAWK_MARKETPLACE',
  }

  /**
   *
   */
  export interface GiftCodesData {
    giftCards: GiftCode[];
  }

  export interface GiftCode {
    code: string | null;
    pin: string | null;
    is_url: boolean;
    merchant: string;
    value: string;
    fulfillment_service: GiftCardOrderFulfillmentService | null;
    card_uuid: string | null;
    ohm_hawk_catalog_product_id: number | null;
    /** iso format datetime string */
    dttm: string | null;
    bar_code_value: string | null;
  }

  export interface FreeSpinToSpend {
    rmw_redeemable_spin_id?: number;
    rmw_spin_id?: number;
  }
}
