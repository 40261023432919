export default function SpikeLogo({className}: {className?: string}) {
  return (
    <svg
      {...{className}}
      width="134"
      height="40"
      viewBox="50 0 134 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="SpikeLogo"
    >
      <path
        d="M218.874 74.6646V87.5666H211.37V22.1481H218.874V69.8547L229.361 58.649H239L226.317 71.8606L240.227 87.5666H230.339L218.874 74.6646Z"
        fill="currentColor"
      />
      <path
        d="M139.285 81.5286C141.969 81.5286 142.827 80.4311 142.827 79.3934C142.827 75.0824 128.129 77.7568 128.129 67.3492C128.129 62.0508 131.911 58.0995 138.377 58.0995C140.931 58.0995 145.631 58.7079 148.924 61.7515L144.932 67.11C143.031 65.7326 140.754 64.9669 138.407 64.9148C135.902 64.9148 135.413 66.0122 135.413 66.7408C135.413 70.8217 149.932 68.8759 149.932 79.1637C149.932 84.4621 146.09 88.3537 138.766 88.3537C134.594 88.3748 130.547 86.9304 127.331 84.2728L131.322 78.5553C133.554 80.4501 136.377 81.5085 139.305 81.5486"
        fill="currentColor"
      />
      <path
        d="M172.523 81.1696C174.085 81.155 175.61 80.6817 176.906 79.8089C178.202 78.9358 179.214 77.7015 179.815 76.2593C180.416 74.8167 180.581 73.2292 180.289 71.6941C179.997 70.1587 179.26 68.7431 178.171 67.6227C177.081 66.502 175.687 65.7261 174.16 65.3907C172.634 65.0554 171.043 65.1752 169.584 65.7359C168.125 66.2966 166.863 67.2732 165.953 68.5443C165.045 69.8157 164.529 71.326 164.47 72.8877V73.5463C164.592 75.6017 165.493 77.5333 166.989 78.9475C168.485 80.362 170.464 81.1532 172.523 81.1598V81.1696ZM156.976 58.6486H164.47V60.7739C166.756 58.9628 169.608 58.0158 172.523 58.0995C176.535 58.0995 180.382 59.6936 183.219 62.5303C186.056 65.3671 187.65 69.2149 187.65 73.227C187.65 77.2391 186.056 81.0866 183.219 83.9233C180.382 86.7604 176.535 88.3541 172.523 88.3541C169.608 88.4379 166.756 87.4912 164.47 85.6801V102.973H156.976V58.6486Z"
        fill="currentColor"
      />
      <path
        d="M194.665 58.6486H202.169V87.5859H194.665V58.6486ZM193.667 49.0892C193.659 48.4651 193.777 47.8458 194.014 47.2684C194.251 46.691 194.603 46.1676 195.047 45.7295C195.492 45.2914 196.021 44.9478 196.601 44.7192C197.182 44.4906 197.803 44.3818 198.427 44.3994C199.047 44.3829 199.664 44.4929 200.241 44.7228C200.818 44.9526 201.341 45.2975 201.78 45.7363C202.219 46.1751 202.563 46.6987 202.793 47.2752C203.023 47.8516 203.133 48.4688 203.117 49.0892C203.133 49.7118 203.023 50.3313 202.794 50.9104C202.565 51.4895 202.221 52.0162 201.782 52.459C201.344 52.9018 200.821 53.2513 200.245 53.4868C199.668 53.7222 199.05 53.8386 198.427 53.8289C197.801 53.8397 197.178 53.7246 196.598 53.4904C196.017 53.2563 195.489 52.9079 195.044 52.4659C194.601 52.0239 194.25 51.4973 194.014 50.9172C193.777 50.3372 193.659 49.7156 193.667 49.0892Z"
        fill="currentColor"
      />
      <path
        d="M256.382 64.9153C254.763 64.8876 253.177 65.3741 251.852 66.3047C250.527 67.235 249.531 68.5618 249.008 70.0943H263.466C263.037 67.1706 259.994 64.9153 256.392 64.9153H256.382ZM256.821 81.5993C258.178 81.6434 259.527 81.3747 260.765 80.8139C262.001 80.2532 263.093 79.4158 263.955 78.3664L269.084 82.4473C267.662 84.3272 265.815 85.843 263.693 86.8697C261.572 87.8969 259.237 88.4055 256.88 88.3545C248.159 88.3545 241.454 81.8389 241.454 73.3869C241.454 65.1651 247.241 58.1102 256.422 58.1102C264.963 58.1102 271 65.0453 271 73.2074C271.002 74.0026 270.942 74.7967 270.82 75.5825H248.758C249.616 79.1147 252.48 81.5695 256.811 81.5695"
        fill="currentColor"
      />
      <defs>
        <linearGradient id="desktop-nav" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stop-color="#FF373C"></stop>
          <stop offset="100%" stop-color="#FFC72C"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M69.484 102.05v-9.511h-4.823v1.064a.116.116 0 0 0-.037-.028 10.243 10.243 0 0 0-5.212-1.398c-5.573 0-10.035 4.376-10.035 9.873a9.815 9.815 0 0 0 3.537 7.54 9.74 9.74 0 0 0-3.537 7.531c0 5.45 4.462 9.91 10.035 9.91 5.61 0 10.072-4.46 10.072-9.91a9.724 9.724 0 0 0-3.536-7.531 9.785 9.785 0 0 0 3.536-7.54zm-15.284 0c0-2.785 2.342-5.172 5.212-5.172 2.703 0 4.916 2.063 5.212 4.59.028.193.037.387.037.582 0 .194-.009.397-.037.583-.296 2.563-2.509 4.626-5.212 4.626-2.87 0-5.212-2.387-5.212-5.21zm10.424 15.663c-.296 2.564-2.509 4.626-5.212 4.626-2.87 0-5.212-2.386-5.212-5.218 0-2.784 2.342-5.171 5.212-5.171 2.703 0 4.916 2.063 5.212 4.589.028.194.037.388.037.582 0 .204-.009.399-.037.592zm33.449-16.385c0-3.66-1.95-4.456-3.901-4.456-1.95 0-3.902.756-3.902 4.377v10.184h-4.895V92.536h4.895v1.392c1.075-.796 2.389-1.75 4.976-1.75 4.14 0 7.722 2.426 7.722 8.354v10.901h-4.895v-10.105zm-23.207 10.105h4.896V92.536h-4.896v18.897zm-34.98 0h4.895V92.536h-4.895v18.897zM24.083 92.536h4.896v1.392c1.831-1.353 3.623-1.75 7.285-1.75v4.694c-6.25 0-7.285 1.75-7.285 5.012v9.549h-4.896V92.536zM10.031 111.95C4.458 111.95 0 107.494 0 102.044c0-5.49 4.458-9.867 10.03-9.867 5.614 0 10.072 4.376 10.072 9.867 0 5.45-4.458 9.906-10.071 9.906zm0-15.078c-2.866 0-5.215 2.387-5.215 5.172 0 2.824 2.349 5.211 5.215 5.211 2.906 0 5.254-2.387 5.254-5.211 0-2.785-2.348-5.172-5.254-5.172z"
        ></path>
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M43 0C24.41-.016 7.92 11.83 2.135 29.356-3.649 46.88 2.587 66.108 17.587 77c15.125-11.008 35.701-11.008 50.826 0 15-10.892 21.236-30.12 15.452-47.644C78.08 11.83 61.59-.016 43 0zm0 64.39c-12.109-.01-21.919-9.752-21.916-21.764.004-12.011 9.82-21.748 21.928-21.75 12.11-.004 21.93 9.727 21.94 21.738a21.669 21.669 0 0 1-6.424 15.403A22.024 22.024 0 0 1 43 64.39z"
          transform="translate(8.583)"
        ></path>
      </g>
    </svg>
  );
}
