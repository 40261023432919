/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import {GlobalStoreState} from './globalStore.types';
import api from '../api';

/**********************
 * Action Definitions
 **********************/

/**
 * An enum representing all possible global store action types. Each enum value
 * must be represented in the `ActionsMap` type.
 */
export enum ActionType {
  SET_BILLBOARD_ANNOUNCEMENT_DISPLAY = 'set-billboard-announcement-display',
  SET_BRAND_BAR_DISPLAY = 'set-brand-bar-display',
  SET_USER = 'set-user',
  SET_USER_REFERRAL_SHARE = 'set-user-referral-share',
  SET_USER_REFERRAL_OFFERS = 'set-user-referral-offers',
  SET_USER_ATTRIBUTES = 'set-user-attributes',
  SET_SHIPPING_ADDRESS = 'set-shipping-address',
  SET_ANNOUNCEMENT = 'set-announcement',
  SET_EXPERIMENTS = 'set-experiments',
  CLEAR_EXPERIMENTS = 'clear-experiments',
  SET_REFERRALS = 'set-referrals',
  SET_USER_SETTINGS = 'set-user-settings',
  SET_EVENT_SUBSCRIPTION = 'set-event-subscription',
  SET_USER_SETTINGS_SUBSCRIPTION = 'set-user-settings-subscription',
  SET_USER_SETTINGS_PHONE_NUMBERS = 'set-user-settings-phone-numbers',
  SET_PHONE_VERIFICATION_CODE = 'set-phone-verification-code',
  UPDATE_USER_UTILITY_INFO = 'update-user-utility-info',
  SET_USER_DEVICE_OFFER = 'set-user-device-offer',
  CLEAR_USER_DEVICE_OFFER = 'clear-user-device-offer',
  SET_USER_ORDER_STATUS = 'set-user-order-status',
  SET_USER_SIGNUP_CAPTCHA_REQUIRED = 'set-user-signup-captcha-required',
  SET_USER_GIFT_CODES = 'set-user-gift-codes',
  SET_SIGNUP_STEP = 'set-signup-step',
  SET_SIGNUP_UTILITY_OPTIONS = 'set-signup-utility-options',
  SET_FULL_UTILITY_LIST = 'set-full-utility-list',
  SET_UTILITY_OUTAGE = 'set-utility-outage',
  SET_SIGNUP_FB_CONVERSION_DATA = 'set-fb-conversion-data',
  SET_USER_LOGIN_CAPTCHA_REQUIRED = 'set-user-login-captcha-required',
  SET_MULTIFACTOR_VERIFICATION_OPTIONS = 'set-multifactor-verification-options',
  SET_REDIRECT = 'set-redirect',
  SET_RHR_LIST = 'set-rhr-list',
  LOGOUT = 'logout',
  SET_CONNECTION_DEVICES = 'set-connection-devices',
  OMIT_CONNECTION_DEVICES = 'omit-connection-devices',
  SET_CONNECTIONS = 'set-connections',
  SET_CONNECTION_CONFIGS = 'set-connection-configs',
  SET_DEVICE_CONFIGS = 'set-device-configs',
  SET_DEVICE_MESSAGES = 'set-device-messages',
  OMIT_DEVICE_MESSAGES = 'omit-device-messages',
  SET_UPCOMING_EVENTS = 'set-upcoming-events',
  SET_PENDING_EVENT = 'set-pending-event',
  SET_HISTORICAL_EVENTS_COUNT = 'set-historical-events-count',
  SET_ENERGY_EVENTS = 'set-energy-events',
  ADD_ENERGY_EVENT = 'add-energy-event',
  REFRESH_ENERGY_EVENT = 'refresh-energy-event',
  UPDATE_ENERGY_EVENT_BASELINE_RECORDS = 'update-energy-event-baseline-records',
  SET_IN_PROGRESS_EVENT = 'set-in-progress-event',
  SET_EVENT_CHECKLIST = 'set-event-checklist',
  SET_EVENT_CHECKLIST_ITEM = 'set-event-checklist-item',
  SET_NEXT_BEST_ACTION = 'set-next-best-action',
  CLEAR_NEXT_BEST_ACTION = 'clear-next-best-action',
  SET_WALLET = 'set-wallet',
  SET_WALLET_CURRENT_POINTS = 'set-wallet-current-points',
  SET_REWARDS = 'set-rewards',
  ADD_FLASH_MESSAGE = 'add-flash-message',
  DROP_FLASH_MESSAGE = 'drop-flash-message',
  SHOW_EXIT_INTENT_MODAL = 'show-exit-intent-modal',
  SET_CURRENT_THEME = 'set-current-theme',
  SET_APP_VERSION_OUTDATED = 'set-app-version-outdated',
  SET_FEATURES = 'set-features',
  SET_LOADING = 'set-loading',
  CLEAR_LOADING = 'clear-loading',
  SET_PLUS_STATUS = 'set-plus-status',
  SET_PLUS_RATE_CONFIGS = 'set-plus-rate-configs',
  SET_VACATION = 'set-vacation',
  SET_AVAILABLE_EGIFTS = 'set-available-egifts',
  SET_SHOW_ACCOUNT_BACK_LINK = 'set-show-back-link',
  SET_FRIEND_BUY_AUTH_DATA = 'set-friend-buy-auth-data',
  SET_FETCH_INSTANCE = 'set-fetch-instance',
  COMPLETE_FETCH_INSTANCE = 'complete-fetch-instance',
  REMOVE_FETCH_INSTANCES = 'remove-fetch-instances',
}

/** Dummy state object for the purposes of easily referencing its type */
const state: Required<GlobalStoreState> = ({} as unknown) as Required<GlobalStoreState>;
/**
 * A map of all global store actions, mapped to the action type. When consuming
 * via the `GlobalStoreAction` type, the map key is asserted as the `type` property.
 * The map must contain exactly every key in the `ActionType` enum.
 */
interface ActionsMap extends ActionsMapBase {
  // TODO: strongly type all of these payloads
  [ActionType.SET_BILLBOARD_ANNOUNCEMENT_DISPLAY]: {
    payload: typeof state.billboardAnnouncementDisplay;
  };
  [ActionType.SET_BRAND_BAR_DISPLAY]: {payload: typeof state.brandBarDisplay};
  [ActionType.SET_USER]: {payload: any};
  [ActionType.SET_USER_REFERRAL_SHARE]: {payload: any};
  [ActionType.SET_USER_REFERRAL_OFFERS]: {payload: typeof state.user.referralOffers};
  [ActionType.SET_USER_ATTRIBUTES]: {payload: any};
  [ActionType.SET_SHIPPING_ADDRESS]: {payload: any};
  [ActionType.SET_ANNOUNCEMENT]: {payload: any};
  [ActionType.SET_EXPERIMENTS]: {payload: any};
  [ActionType.CLEAR_EXPERIMENTS]: {payload: any};
  [ActionType.SET_REFERRALS]: {payload: any};
  [ActionType.SET_USER_SETTINGS]: {payload: any};
  [ActionType.SET_EVENT_SUBSCRIPTION]: {payload: any};
  [ActionType.SET_USER_SETTINGS_SUBSCRIPTION]: {payload: any};
  [ActionType.SET_USER_SETTINGS_PHONE_NUMBERS]: {payload: any};
  [ActionType.SET_PHONE_VERIFICATION_CODE]: {payload: any};
  [ActionType.UPDATE_USER_UTILITY_INFO]: {payload: any};
  [ActionType.SET_USER_DEVICE_OFFER]: {payload: any};
  [ActionType.CLEAR_USER_DEVICE_OFFER]: {payload: any};
  [ActionType.SET_USER_ORDER_STATUS]: {payload: any};
  [ActionType.SET_USER_SIGNUP_CAPTCHA_REQUIRED]: {payload: any};
  [ActionType.SET_USER_GIFT_CODES]: {payload: typeof state.user.giftCodes};
  [ActionType.SET_SIGNUP_STEP]: {payload: string};
  [ActionType.SET_SIGNUP_UTILITY_OPTIONS]: {payload: typeof state.signup.utilityOptions};
  [ActionType.SET_FULL_UTILITY_LIST]: {payload: typeof state.signup.fullUtilityList};
  [ActionType.SET_UTILITY_OUTAGE]: {payload: typeof state.signup.utilityOutage};
  [ActionType.SET_SIGNUP_FB_CONVERSION_DATA]: {payload: typeof state.signup.fbConversionData};
  [ActionType.SET_USER_LOGIN_CAPTCHA_REQUIRED]: {payload: any};
  [ActionType.SET_MULTIFACTOR_VERIFICATION_OPTIONS]: {payload: any};
  [ActionType.SET_REDIRECT]: {payload: string | Partial<Location>};
  [ActionType.SET_RHR_LIST]: {payload: typeof state.rhrList};
  [ActionType.LOGOUT]: {payload: any};
  [ActionType.SET_CONNECTION_DEVICES]: {
    payload: any;
    connectionId: number | string; // ?
  };
  [ActionType.OMIT_CONNECTION_DEVICES]: {payload: any};
  [ActionType.SET_CONNECTIONS]: {payload: any};
  [ActionType.SET_CONNECTION_CONFIGS]: {payload: any};
  [ActionType.SET_DEVICE_CONFIGS]: {payload: any};
  [ActionType.SET_DEVICE_MESSAGES]: {payload: any};
  [ActionType.OMIT_DEVICE_MESSAGES]: {payload: any};
  [ActionType.SET_UPCOMING_EVENTS]: {payload: any};
  [ActionType.SET_PENDING_EVENT]: {payload: any};
  [ActionType.SET_HISTORICAL_EVENTS_COUNT]: {payload: any};
  [ActionType.SET_ENERGY_EVENTS]: {payload: any};
  [ActionType.ADD_ENERGY_EVENT]: {payload: api.Events.EventData};
  [ActionType.REFRESH_ENERGY_EVENT]: {payload: any};
  [ActionType.UPDATE_ENERGY_EVENT_BASELINE_RECORDS]: {
    payload: any;
    eventId: string | number;
  };
  [ActionType.SET_IN_PROGRESS_EVENT]: {payload: any};
  [ActionType.SET_EVENT_CHECKLIST]: {payload: any};
  [ActionType.SET_EVENT_CHECKLIST_ITEM]: {payload: any};
  [ActionType.SET_NEXT_BEST_ACTION]: {payload: any};
  [ActionType.CLEAR_NEXT_BEST_ACTION]: {payload: any};
  [ActionType.SET_WALLET]: {payload: any};
  [ActionType.SET_WALLET_CURRENT_POINTS]: {payload: any};
  [ActionType.SET_REWARDS]: {payload: typeof state.rewards};
  [ActionType.ADD_FLASH_MESSAGE]: {payload: any};
  [ActionType.DROP_FLASH_MESSAGE]: {payload: any};
  [ActionType.SHOW_EXIT_INTENT_MODAL]: {payload: any};
  [ActionType.SET_CURRENT_THEME]: {payload: any};
  [ActionType.SET_APP_VERSION_OUTDATED]: {payload: any};
  [ActionType.SET_LOADING]: {payload: string};
  [ActionType.CLEAR_LOADING]: {payload: string};
  [ActionType.SET_FEATURES]: {payload: typeof state.features.featureKeys};
  [ActionType.SET_PLUS_STATUS]: {payload: typeof state.plus.status};
  [ActionType.SET_PLUS_RATE_CONFIGS]: {payload: typeof state.plus.rateConfigs};
  [ActionType.SET_VACATION]: {payload: typeof state.userSettings.vacationEndDttm};
  [ActionType.SET_AVAILABLE_EGIFTS]: {payload: typeof state.cashout.eGifts.availableEGifts};
  [ActionType.SET_SHOW_ACCOUNT_BACK_LINK]: {payload: typeof state.showAccountBackLink};
  [ActionType.SET_FRIEND_BUY_AUTH_DATA]: {payload: typeof state.friendBuy.auth};
  [ActionType.SET_FETCH_INSTANCE]: {payload: typeof state.fetchInstances[0]};
  [ActionType.COMPLETE_FETCH_INSTANCE]: {payload: string};
  [ActionType.REMOVE_FETCH_INSTANCES]: {payload: string[]};
}

/**********************
 * GlobalStoreAction Type
 **********************/

/**
 * Discriminating union of all global store actions. This type ensures every action
 * type from the `ActionType` enum is represented in the map.
 */
export type GlobalStoreAction<T extends ActionType = ActionType> = T extends GlobalStoreActionUnion<
  T
>['type']
  ? PayloadOf<T> extends GlobalStoreActionUnion<T>['payload']
    ? GlobalStoreActionUnion<T>
    : never
  : never;

/** */
type GlobalStoreActionUnion<T extends ActionType> = ActionsMap[T] & {
  type: T;
  payload: PayloadOf<T>;
};

/**********************
 * Helpers
 **********************/

/**
 * Returns the payload type for the the given action type `T`
 */
export type PayloadOf<T extends ActionType> = ActionsMap[T]['payload'];

/**
 * Base structure of the `ActionsMap`, which must not contain sub objects with a `type` key.
 * The `GlobalStoreAction` type mutates `ActionsMap` and asserts the map key as the `type` property
 */
type ActionsMapBase = Record<ActionType, {type?: never; payload: unknown}>;
