/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
/* eslint-disable camelcase */
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';
import {CurrencyType} from 'utils';

export namespace OhmPlus {
  /**********
   * Interactions
   **********/

  /**
   * Get user's CURRENT ohmPlus-iness
   * @param userId Optional specify non-current userId
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getStatus<TConfigData = never>(
    userId?: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<StatusData, TConfigData>> {
    return await axios
      .get<StatusData, AxiosResponse<StatusData, TConfigData>, TConfigData>(
        `/api/v2/ohm_plus/user`,
        {
          ...config,
          params: {
            user_id: userId,
          },
        },
      )
      .then(r => {
        // rehydrate dates
        if (r.data.payoutInterval !== null) {
          r.data.payoutInterval.start = new Date(r.data.payoutInterval.startIsoDate);
          r.data.payoutInterval.end = new Date(r.data.payoutInterval.endIsoDate);
        }
        return r;
      });
  }

  /**
   * Get all possible rate config options for the user
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getAllRateConfigs<TResponse = RateConfig[], TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/ohm_plus/rate_configs`, config);
  }

  /**
   * Get rate config option for the user, by id
   * @param id Rate config id to get
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getRateConfig<TResponse = RateConfig, TConfigData = never>(
    id: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/ohm_plus/rate_config/${id}`, config);
  }

  /**
   * Get user's list of payout periods
   * @param userId Optional specify non-current userId
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getPayoutPeriods<TConfigData = never>(
    userId?: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<PayoutPeriod[], TConfigData>> {
    return await axios
      .get<PayoutPeriod[], AxiosResponse<PayoutPeriod[], TConfigData>, TConfigData>(
        `/api/v2/ohm_plus/payout_periods`,
        {
          ...config,
          params: {
            user_id: userId,
          },
        },
      )
      .then(r => {
        // rehydrate dates
        const updatedData = r.data.map(period => ({
          ...period,
          start: new Date(period.startIsoDate),
          end: new Date(period.endIsoDate),
          closed: period.closedIsoDate ? new Date(period.closedIsoDate) : null,
        }));

        return {
          ...r,
          data: updatedData,
        };
      });
  }

  /**********
   * Types
   **********/
  /**
   *
   */
  export interface StatusData {
    enrolledRate: RateConfig | null;
    payoutInterval: PayoutInterval | null;
    currentPayout: Payout;
    currentFailedEvents: EventSummary[];
    periodEnrolledRates: RateEnrollment[];
    deviceSummary: UserDeviceSummary;
  }

  export interface PayoutPeriod {
    id: number;
    start: Date;
    startIsoDate: string;
    end: Date;
    endIsoDate: string;
    /**
     * the list of all the enrolled rates which overlap the period.
     * may include rates which started before or ended after the period
     */
    periodEnrolledRates: RateEnrollment[];
    /** the final payout for the period, empty if the period had no payout */
    finalPayout: Payout | null;
    /** all the event summaries which occurred in the period */
    eventSummaries: EventSummary[];
    /** ISO-8061 formatted date marking the period as having final accounting completed */
    closedIsoDate: string | null;
    closed: Date | null;
  }

  /**
   * Summary record of device type strings and quantities of said devices
   */
  //  TODO: possible improvement here to make an enum of the device types instead of string
  export type UserDeviceSummary = Record<string, number>;

  /**
   *
   */
  export interface RateConfig {
    id: number;
    deviceRequirements: DeviceRequirement[];
    payout: Payout;
  }

  /**
   * represents a user's enrollment in a single rate, with start and (nullable) end dttms
   */
  export interface RateEnrollment {
    id: number;
    rate: RateConfig;
    // TODO: rehydrate these dates
    enrollStartIsoDate: string;
    enrollEndIsoDate: string | null;
  }

  /**
   * General currency payout value
   */
  export interface Payout {
    currencyType: CurrencyType;
    value: number;
  }

  /**
   *
   */
  export interface PayoutInterval {
    start: Date;
    startIsoDate: string;
    end: Date;
    endIsoDate: string;
  }

  /**
   *
   */
  export interface DeviceRequirement {
    id: number;
    quantity: number;
    deviceTypes: string[];
    uiLabelSingular: string;
    uiLabelPlural: string;
  }

  /**
   * communcates which deviceIds meet a device requirement
   */
  export interface DeviceRequirementAllocation {
    /** a requirement from the user's enrolled rate */
    requirement: DeviceRequirement;
    /** the ids of devices which were deemed to have met this requirement */
    deviceIds: number[];
  }

  export interface EventSummary {
    id: number;
    /**the ID of the FOHU record the strike is linked to (i.e. the event) */
    fOhmHourUserId: number;
    /** whether the event is a failure for OhmPlus purposes */
    isFailure: boolean;
    /** _IF_ the event was a failure, why did it fail? */
    failureReason: FailureReason;
    /** the rate config the user was enrolled in at the time of the event */
    rateConfig: RateConfig | null;
    isForgiven: boolean;
    forgivenReason: string | null;
    /** the allocation of participating devices to the rate requirements */
    deviceRequirementAllocations: DeviceRequirementAllocation[];
    /**
     * a list of the current devices (if before or during  the event)
     */
    deviceList: SummaryDeviceParticipation[];
  }

  export type PreEventSummary = Pick<
    EventSummary,
    'deviceRequirementAllocations' | 'deviceList' | 'isFailure' | 'failureReason'
  >;

  /**The base reason a strike was issued*/
  export enum FailureReason {
    /**
     * when not enough devices participate to meet the rate requirements,
     * which includes individual devices being opted out
     */
    DEVICE_PARTICIPATION = 'DEVICE_PARTICIPATION',
    /**when a user opts out of an entire event*/
    EVENT_OPT_OUT = 'EVENT_OPT_OUT',
  }

  /**
   * the ohm plus summary of a device's participation for an event
   */
  export interface SummaryDeviceParticipation {
    device_id: number;
    device_type: string;
    participation: DeviceParticipationState;
  }

  /** The state of a particular device during the event
   * (or our estimation of the device's state when the event eventually happens)
   */
  export enum DeviceParticipationState {
    // INCLUDED states //
    /** the device successfully participated */
    OK = 'OK',

    // EXCLUDED states //
    /** the device was opted out, either individually or by the user opting out of the whole event */
    OPT_OUT = 'OPT_OUT',
    /** for some reason there was no device toggle record for this device/event combo */
    NO_DEVICE_TOGGLE = 'NO_DEVICE_TOGGLE',
    /** the device was already off when the event started */
    ALREADY_OFF = 'ALREADY_OFF',
    /** the device was manually turned on before the during check */
    TURNED_ON_DURING = 'TURNED_ON_DURING',

    // FAILED states //
    /** we were unable to turn off the device for some reason */
    FAILED = 'FAILED',
    /** the device's connection had some issue which prevented it from participating */
    NOT_HEALTHY = 'NOT_HEALTHY',
    /** the device was offline and did not participate */
    OFFLINE = 'OFFLINE',

    // ISSUE states //
    /** some other state, which is also a failure because its not OK */
    OTHER = 'OTHER',
  }
}
