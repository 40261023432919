/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import styled from 'styled-components';

import api from 'api';
import {gql} from '@apollo/client';
import {useFeature} from 'hooks/useFeature';
import {useImagePreload} from 'hooks/useImagePreload';
import {ENV} from 'config/globals';
import {Oem} from 'config/environments/env.types';
import OhmLogo from 'components/shared/svgs/OhmLogo';
import OhmLogoBeta from 'components/shared/svgs/OhmLogoBeta';
import SpikeLogo from 'components/shared/svgs/SpikeLogo';
import {useViewerFirstLoadQuery} from 'graphql/api.generated';

// GraphQL fragment declaration
/**
 * this is the declaration of what this component needs on the feature query, declared here to make
 * future pruning of fields clearer. When/if this component needs different data, it should be updated
 * in this (or other) fragments to make that dependency clear
 *
 * This fragment is then imported in the .gql.ts file for the viewerFeatures query to make our
 * dependence on it clearer
 */
export const brandLogoUserGql = gql`
  fragment brandLogoFields on User {
    uiCustomization {
      customBrandBarImageUrl
    }
  }
`;

const StyledCustomLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    padding-bottom: 4px;
    padding-top: 4px;
  }
  height: 100%;
`;

interface BrandLogoProps {
  className?: string;
}

export function BrandLogo({className}: BrandLogoProps) {
  const showOhmBetaBadge = useFeature(api.Features.Tree.Presentational.OhmBetaLogo);
  const viewerData = useViewerFirstLoadQuery({
    fetchPolicy: 'cache-first',
  });
  // this is the state of the prefetched custom logo
  const customLogoUrl =
    viewerData?.data?.viewer.uiCustomization.customBrandBarImageUrl ?? undefined;
  const isCustomLogoImageLoaded = useImagePreload(customLogoUrl);

  if (customLogoUrl && isCustomLogoImageLoaded) {
    return (
      <StyledCustomLogoContainer {...{className}}>
        <img src={customLogoUrl} alt="OhmConnect Logo" height="100%" data-testid="CustomLogo" />
      </StyledCustomLogoContainer>
    );
  }

  if (ENV.oem === Oem.ORIGIN) return <SpikeLogo {...{className}} />;

  if (showOhmBetaBadge) {
    return <OhmLogoBeta {...{className}} />;
  } else {
    return <OhmLogo {...{className}} />;
  }
}
