/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import api from 'api';
import {useFeature} from 'hooks/useFeature';
import {BottomNavBar, BottomNavLink} from '../navigation';
import {HomeFill} from 'components/shared/svgs/navigation/HomeFill';
import {HomeLine} from 'components/shared/svgs/navigation/HomeLine';
import {EventsFill} from 'components/shared/svgs/navigation/EventsFill';
import {EventsLine} from 'components/shared/svgs/navigation/EventsLine';
import {DevicesFill} from 'components/shared/svgs/navigation/DevicesFill';
import {DevicesLine} from 'components/shared/svgs/navigation/DevicesLine';
import {RewardsCashFill} from 'components/shared/svgs/navigation/RewardsCashFill';
import {RewardsCashLine} from 'components/shared/svgs/navigation/RewardsCashLine';
import {RewardsFill} from 'components/shared/svgs/navigation/RewardsFill';
import {RewardsLine} from 'components/shared/svgs/navigation/RewardsLine';
import {ReferFill} from 'components/shared/svgs/navigation/ReferFill';
import {ReferLine} from 'components/shared/svgs/navigation/ReferLine';
import {useGetUtilityEnrollmentModalHidden} from '../../../store/selectors';
import useIsLGWebView from '../../../hooks/useIsLGWebView';
import {useLocation} from 'react-router-dom';
import {useExperimentExposed} from '../../../hooks/useExperiment';
import {useGlobalState} from '../../../store';
import {ComponentPropsWithoutRef} from '../../../types';

export const PrimaryNavBar = React.forwardRef<
  HTMLElement,
  Omit<ComponentPropsWithoutRef<typeof BottomNavBar>, 'navLinks'>
>((props, ref) => {
  const shouldShowPrimaryNav = useGetShouldShowPrimaryNav();
  const showCashRewardsIcon = useFeature(
    api.Features.Tree.Presentational.PrimaryNav.RewardsCashIcon,
  );

  const navLinks: BottomNavLink[] = [
    {label: 'Home', icon: HomeLine, activeIcon: HomeFill, to: '/', end: true},
    {label: 'Events', icon: EventsLine, activeIcon: EventsFill, to: '/events'},
    {label: 'Devices', icon: DevicesLine, activeIcon: DevicesFill, to: '/devices'},
    {
      label: 'Rewards',
      icon: showCashRewardsIcon ? RewardsCashLine : RewardsLine,
      activeIcon: showCashRewardsIcon ? RewardsCashFill : RewardsFill,
      to: '/rewards',
      extraPaths: ['/wallet'],
    },
    {label: 'Refer', icon: ReferLine, activeIcon: ReferFill, to: '/invite'},
  ];

  return navLinks.length && shouldShowPrimaryNav ? (
    <BottomNavBar ref={ref} {...{navLinks}} {...props} />
  ) : null;
});

function useGetShouldShowPrimaryNav() {
  const globalState = useGlobalState();
  const utilityEnrollmentModalHidden = useGetUtilityEnrollmentModalHidden()();
  const experimentExposed = useExperimentExposed();
  const location = useLocation();
  const isLGWebView = useIsLGWebView();

  const userIsAuthenticated = !!globalState.user.id;
  const hideNavBar =
    useFeature(api.Features.Tree.Presentational.PrimaryNav.Hide) && !utilityEnrollmentModalHidden;

  const showNavBar =
    !hideNavBar &&
    userIsAuthenticated &&
    experimentExposed('spa_full') &&
    !isLGWebView() &&
    !location.pathname.startsWith('/signup') &&
    !location.pathname.startsWith('/login') &&
    !location.pathname.startsWith('/forgot-password') &&
    !location.pathname.startsWith('/new-user') &&
    !location.pathname.startsWith('/stripe/checkout/setup') &&
    !location.pathname.startsWith('/stripe/checkout/payment') &&
    !location.pathname.startsWith('/promo/redeem') &&
    !location.pathname.startsWith('/coupons/claimed') &&
    !location.pathname.startsWith('/wallet/egift') &&
    !location.pathname.startsWith('/update-google-nest') &&
    // @todo remove when PrizeForm is released
    !location.pathname.startsWith('/prize-form');

  return showNavBar;
}
