/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig, LoginMethod} from '../env.types';

const BACKEND_URL = `${window.location.protocol}//${
  window.location.host.endsWith(':3000')
    ? window.location.host.replace(':3000', ':6543')
    : window.location.host.replace(':5000', ':6543')
}`;

export const ORG_DEVELOPMENT_ENV: EnvConfig = {
  ...(require('./org.beta.env').ORG_BETA_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.DEVELOPMENT,
  name: 'Origin Development',
  backendBaseUrl: BACKEND_URL,
  // Unless you are testing sso connect, sso with origin is setup
  // only for production and beta
  defaultLoginMethod: LoginMethod.PASSWORD,
  ssoLoginUrl: `${BACKEND_URL}/connect/origin`,
  cookieDomain: window.location.host.replace(/:[0-9]{4}$/, ''),
  // Note: Key below is copied from backend config.
  googleRecaptchaSiteKey: '6LcR1HoUAAAAAPxiOT2l3y8oq0-bd9K9mRWHy2wJ',
  // Note: Key below is copied from backend config.
  // todo: Provide valid key accordingly.
  // (key here id for testing and will always result in success.)
  hcaptchaSiteKey: '10000000-ffff-ffff-ffff-000000000001',
  gmailClientId: '442034863238-0v8pik6jv5gjf733mpm897v1ituqdf35.apps.googleusercontent.com',
};
