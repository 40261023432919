/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */

import {ButtonSize, ButtonVariation} from 'components/shared/Button';
import {
  AliePlugReview,
  ReviewTestimonialProps,
  RuthOhmReview,
} from 'components/shared/ReviewTestimonial';
import {UnderlinedLink} from 'components/shared/styled';
import {ENV} from 'config/globals';

/**
 *
 */
export const DEVICE_OFFER_CTA_LABEL = 'Order a $0.99 Plug';

/**
 *
 */
const DEFAULT_OFFER_REVIEWS: ReviewTestimonialProps[] = [AliePlugReview, RuthOhmReview];

/**
 *
 */
const DEFAULT_OFFER_PRIMARY_CTA_LINK = `${ENV.backendBaseUrl}/shop/redeem/signup/plus`;

/**
 *
 */
export const DEVICE_OFFER_CONTENT_VARIANT_A: DeviceOfferContent = {
  title: 'Get your first smart plug for $0.99!',
  item: {
    label: 'OhmPlug',
    description:
      // eslint-disable-next-line max-len
      'Connect this smart plug to your fridge or other high energy-use appliances and get paid every month for saving energy automatically.',
    originalPrice: '$11.99',
    price: '$0.99',
    imageUrl: 'images/offers/ohmPlug_static_offer_image.png',
  },
  showPayoutTable: true,
  ctas: [
    {
      content: 'Order now',
      url: DEFAULT_OFFER_PRIMARY_CTA_LINK,
      variation: ButtonVariation.PRIMARY,
      size: ButtonSize.STANDARD,
      onConfirm: () => {
        // TODO: track this click
      },
    },
  ],
  finePrint: 'Limited time offer. Get yours today. Limit one per member.',
  reviews: DEFAULT_OFFER_REVIEWS,
  footer: <UnderlinedLink to="/">Skip for now</UnderlinedLink>,
};

/**
 *
 */
export const DEVICE_OFFER_CONTENT_VARIANT_B: DeviceOfferContent = {
  title: 'One-time offer for new users',
  item: {
    label: 'Add the OhmPlug',
    description:
      // eslint-disable-next-line max-len
      'Connect this smart plug to a home appliance and get paid every month for saving energy automatically.',
    imageUrl: 'images/offers/ohmPlug_static_offer_image.png',
  },
  showPayoutTable: true,
  ctas: [
    {
      content: '$0.99 one-time offer',
      url: DEFAULT_OFFER_PRIMARY_CTA_LINK,
      variation: ButtonVariation.PRIMARY,
      size: ButtonSize.STANDARD,
      onConfirm: () => {
        // TODO: track this click
      },
    },
  ],
  finePrint: (
    <>
      {'or '}
      <UnderlinedLink to="/">decline this one-time offer</UnderlinedLink>
    </>
  ),
  reviews: DEFAULT_OFFER_REVIEWS,
};

/**
 *
 */
export interface DeviceOfferContent {
  title?: string;
  item: DeviceOfferItem;
  showPayoutTable?: boolean;
  ctas: DeviceOfferCta[];
  finePrint?: string | JSX.Element | null;
  reviews?: ReviewTestimonialProps[];
  footer?: string | JSX.Element | null;
}

/**
 *
 */
export interface DeviceOfferItem {
  label?: string;
  description?: string;
  originalPrice?: string;
  price?: string;
  imageUrl?: string;
}

/**
 *
 */
export interface DeviceOfferCta {
  url?: string;
  to?: string;
  target?: '_blank';
  content?: JSX.Element | string | null;
  onConfirm: () => void;
  size?: ButtonSize;
  variation?: ButtonVariation;
}
