/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {ActionType, GlobalStoreAction} from 'store';
import {RequiredOnly} from 'types';

/**
 * A instance of a fetch request
 */
export type FetchInstance = {
  /** The id of the fetch instance */
  id: string;
  /** The id of the fetch, unique by the fetch url */
  fetchId: string;
  /** Time fetch instance was initiated */
  initiated: Date;
  /** Time fetch instance was completed (whether aborted, successful or error) */
  completed?: Date;
  /** The abort controller for this instance of the fetch */
  abortController: AbortController;
};

/**
 * Fetch state data for a single set of fetch instances with a common fetch id
 */
export interface FetchState {
  /** The id of the fetch, unique by the fetch url */
  fetchId?: string;
  /** The last time a fetch for this fetch id was initiated */
  lastInitiated?: Date;
  /** The last time a fetch for this fetch id was updated */
  lastUpdated?: Date;
  /** Whether or not an instance for this fetch id is in progress */
  isLoading: boolean;
  /** Abort all instances of this fetch */
  abortAll: () => void;
  /** The instances of this fetch */
  instances: FetchInstance[];
}

/**
 * Options to pass into the useFetch hook
 */
export interface UseFetchOptions {
  /** Optional logging to the browser console (in Dev only, never logs on production) */
  debugLogging?: boolean;
}

export enum DeduplicationMode {
  /** Ignore this fetch, if one is already in-flight */
  IgnoreSubsequent = 'IgnoreSubsequent',
  /** Force this fetch to execute and abort an existing instance, if already in-flight */
  ReplaceExisting = 'ReplaceExisting',
  /** Allow fetch to run concurrently, even if another is already in-flight */
  RunConcurrently = 'RunConcurrently',
}

/**
 * DoFetch options
 * @template R Type of response data
 * @template D Type of config data passed in
 * @template V (optional) Type of variables
 * @template T (inferred) Type of ActionType to use in the action
 */
export type DoFetchOptions<R, D, V extends {}, T extends ActionType> = {
  /** The mode by which the fetch will be deduplicated (or not) if one is already in-flight */
  deduplicationMode?: DeduplicationMode;
  /** Fetch data (if fetching POST, for example) */
  data?: D;
  /** Fetch config */
  config?: FetchRequestConfig<R, D>;
  /** Optional action resolver function, to resolve an action from a response */
  actionResolver?: (response: FetchResponse<R, D>) => GlobalStoreAction<T>;
} & (keyof RequiredOnly<V> extends never
  ? {
      /** Variables used to customize the fetch path */
      variables?: V;
    }
  : {
      /** Variables used to customize the fetch path */
      variables: V;
    });

/**
 * UseFetch hook return, including a response and loading status
 * @template R Type of response data
 * @template D Type of config data passed in
 * @template V (optional) Type of variables
 * @template T (inferred) Type of ActionType to use in the action
 */
export type UseFetchReturn<R, D, V extends {} = {}, T extends ActionType = ActionType> = {
  /**
   * @deprecated WARNING: Do not rely directly on the `response`, as this hook will have no
   * response if it's been deduplicated. Instead, pass in an `actionResolver` to dispatch the response
   * to the store, then have your component rely on the store data. This way, regardless of where
   * the fetch was exectued, all dependencies of the data will always have the most up-to-date
   * data, since the store will always be up to date.
   */
  readonly response?: FetchResponse<R, D>;
  /**
   * @deprecated WARNING: Do not rely directly on the `responsePromise`, as this hook will have no
   * response if it's been deduplicated. Instead, pass in an `actionResolver` to dispatch the response
   * to the store, then have your component rely on the store data. This way, regardless of where
   * the fetch was exectued, all dependencies of the data will always have the most up-to-date
   * data, since the store will always be up to date.
   */
  readonly responsePromise?: Promise<FetchResponse<R, D>>;
  /**
   * @deprecated WARNING: Do not rely directly on the `data`, as this hook will have no
   * response if it's been deduplicated. Instead, pass in an `actionResolver` to dispatch the response
   * to the store, then have your component rely on the store data. This way, regardless of where
   * the fetch was exectued, all dependencies of the data will always have the most up-to-date
   * data, since the store will always be up to date.
   */
  readonly data?: R;
  /** A callback function which executes the fetch synchronously and returns a cleanup callback */
  doFetch: DoFetchFn<R, D, V, T>;
  /** A callback function which executes the fetch asynchronously and returns a promise */
  doFetchAsync: DoFetchAsyncFn<R, D, V, T>;
  /** The instances of this fetch which were initiated with this specific instance of the fetch hook */
  localInstances: FetchInstance[];
  /**
   * A callback to cleanup (abort) all fetch instances created by this instance of the hook
   * and remove them from store of instances in global state
   */
  cleanup: FetchCleanupCallback;
} & Readonly<FetchState>;

/**
 * A callback function which executes the fetch synchronously and returns a cleanup callback
 * @template R Type of response data
 * @template D Type of config data passed in
 * @template V (optional) Type of variables
 * @template T (inferred) Type of ActionType to use in the action
 */
export type DoFetchFn<
  R,
  D,
  V extends {} = {},
  T extends ActionType = ActionType
> = keyof RequiredOnly<V> extends never
  ? (doFetchOptions?: DoFetchOptions<R, D, V, T>) => FetchCleanupCallback
  : (doFetchOptions: DoFetchOptions<R, D, V, T>) => FetchCleanupCallback;

/**
 * A callback function which executes the fetch asynchronously and returns a promise
 * @template R Type of response data
 * @template D Type of config data passed in
 * @template V (optional) Type of variables
 * @template T (inferred) Type of ActionType to use in the action
 */
export type DoFetchAsyncFn<
  R,
  D,
  V extends {} = {},
  T extends ActionType = ActionType
> = keyof RequiredOnly<V> extends never
  ? (doFetchOptions?: DoFetchOptions<R, D, V, T>) => Promise<FetchResponse<R, D> | undefined>
  : (doFetchOptions: DoFetchOptions<R, D, V, T>) => Promise<FetchResponse<R, D> | undefined>;
/**
 * Fetch response
 * @template R Type of response data
 * @template D Type of config data passed in
 */
export type FetchResponse<R, D> = AxiosResponse<R, D>;

/**
 * Fetch request config type
 * @template R Type of response data
 * @template D Type of config data passed in
 */
export type FetchRequestConfig<
  // NOTE: R is required here, even though its not used, so we can assert
  // return type safety elsewhere, just by passing around a properly typed config
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _R,
  D
> = AxiosRequestConfig<D>;

/**
 * Supported fetch methods
 */
export enum FetchMethod {
  POST = 'POST',
  GET = 'GET',
}

/**
 * A wrapper for a useFetch hook which simplifies the arguments for ease of end use.
 * Can be used to wrap a useFetch hook and prefill arguments like fetch method and fetch url
 * @template R Type of response data
 * @template D Type of config data passed in
 * @template V (optional) Type of variables
 */
export type FetchHook<R, D, V extends {} = {}> = <T extends ActionType>(
  options?: UseFetchOptions,
) => UseFetchReturn<R, D, V, T>;

/**
 * A cleanup function which aborts an in progress fetch
 */
export type FetchCleanupCallback = () => void;
