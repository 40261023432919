/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */

/**
 *
 */
export enum EventStatus {
  PREPARING = 'PREPARING',
  PREPARING_OPTED_OUT = 'PREPARING_OPTED_OUT',
  PARTICIPATING = 'PARTICIPATING',
  OPTED_OUT = 'OPTED_OUT',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  FAILURE_OPTED_OUT = 'FAILURE_OPTED_OUT',
  AWAITING_RESULT = 'AWAITING_RESULT',
  FORGIVEN = 'FORGIVEN',
}

/**
 *
 */
export enum EventPhase {
  PRE_EVENT = 'PRE_EVENT',
  IN_PROGRESS = 'IN_PROGRESS',
  POST_EVENT = 'POST_EVENT',
}
