/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import PopupAnnouncement from 'components/shared/PopupAnnouncement';
import {useFeature} from 'hooks/useFeature';
import api from 'api';
import {useGlobalState} from 'store';
import {useSetUserAttributes} from 'store/actions';
import {WithComponentPropsWithoutRef} from 'types';
import {ButtonSize, ButtonVariation} from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import {useHighestPayoutRateConfig} from 'store/ohmPlus';
import {formatForCurrency, trackStructuredEvent} from 'utils';
import {DEVICE_OFFER_CTA_LABEL} from 'components/DeviceOffer';
import {ButtonContentWithIcon} from 'components/shared/ButtonContentWithIcon';

export default function DeviceConnectionBillboard({
  ...props
}: WithComponentPropsWithoutRef<typeof PopupAnnouncement>) {
  const globalState = useGlobalState();
  const setUserAttributes = useSetUserAttributes();
  const ATTRIBUTE_KEY = 'BILLBOARD_DEVICE_CONNECTION_COLLAPSED';
  const isCollapsed: boolean = globalState?.user?.attributes[ATTRIBUTE_KEY] === '1';

  async function handleOnCollapse(isNowCollapsed: boolean) {
    if (!isNowCollapsed) {
      trackStructuredEvent({
        category: 'billboard',
        action: 'view',
        label: 'device_limbo' + (showDeviceOffer ? '_with_offer' : '_without_offer'),
      });
    }
    await setUserAttributes({[ATTRIBUTE_KEY]: isNowCollapsed ? '1' : '0'});
  }

  const showClassicCopy = useFeature(
    api.Features.Tree.Onboarding.BillboardAnnouncement.DeviceConnection.OhmClassicCopy,
  );
  const showPlusCopy = useFeature(
    api.Features.Tree.Onboarding.BillboardAnnouncement.DeviceConnection.OhmPlusCopy,
  );
  const showDeviceOffer = useFeature(api.Features.Tree.Promo.OhmPlus.SitewideOffer.OhmPlugDiscount);

  const highestPayoutRateConfig = useHighestPayoutRateConfig();
  const maxPlusRate = highestPayoutRateConfig
    ? formatForCurrency(
        highestPayoutRateConfig.payout.value,
        highestPayoutRateConfig.payout.currencyType,
        {
          notation: 'compact',
        },
      )
    : undefined;

  return (
    <>
      {showPlusCopy || showClassicCopy ? (
        <PopupAnnouncement
          {...props}
          options={{
            header: showPlusCopy
              ? `Link devices and earn ` +
                (maxPlusRate ? `up to ${maxPlusRate} ` : '') +
                `every month`
              : showClassicCopy
              ? 'Link devices and save more energy'
              : '',
            message: '',
            includeIcon: false,
            dismissable: false,
            collapsable: true,
            isCollapsed: isCollapsed,
            collapseLabel: 'Hide',
            uncollapseLabel: showDeviceOffer ? DEVICE_OFFER_CTA_LABEL : 'Link Your Devices',
            onCollapse: handleOnCollapse,
            align: 'center',
            ctaDirection: 'column',
            ctas: [
              showDeviceOffer
                ? {
                    to: '/device_offer',
                    confirmTrackId: '',
                    onConfirm: () => {
                      trackStructuredEvent({
                        category: 'billboard',
                        label: 'device_limbo_device_offer',
                        action: 'click',
                      });
                    },
                    content: (
                      <ButtonContentWithIcon
                        iconVariation={Icon.variations.ORDER}
                        label={DEVICE_OFFER_CTA_LABEL}
                        showCarat={true}
                      />
                    ),
                    variation: ButtonVariation.PRIMARY,
                    size: ButtonSize.STANDARD,
                  }
                : null,
              {
                to: '/devices/add',
                confirmTrackId: '',
                onConfirm: () => {
                  trackStructuredEvent({
                    category: 'billboard',
                    label: 'device_limbo_add_device',
                    action: 'click',
                  });
                },
                content: (
                  <ButtonContentWithIcon
                    iconVariation={Icon.variations.DEVICES}
                    label="Link your devices"
                    showCarat={true}
                  />
                ),
                variation: ButtonVariation.PRIMARY,
                size: ButtonSize.STANDARD,
              },
            ],
          }}
        />
      ) : null}
    </>
  );
}
