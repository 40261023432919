/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import styled from 'styled-components';

const Review = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px 0;
`;

const ReviewDescription = styled.div`
  font-style: italic;
`;

const ReviewStarsWrapper = styled.span`
  padding: 8px 0;
`;

const ReviewStar = styled.span`
  font-family: sans-serif;
  margin: 0 3px;
`;

const ReviewAttribution = styled.span`
  margin-left: 8px;
`;

export interface ReviewTestimonialProps {
  review: string;
  stars: 0 | 1 | 2 | 3 | 4 | 5;
  attribution: string;
}

export function ReviewTestimonial({
  review,
  stars,
  attribution,
}: ReviewTestimonialProps): JSX.Element {
  return (
    <Review>
      <ReviewDescription>{review}</ReviewDescription>
      <ReviewStarsWrapper>
        {[...Array(stars)].map((_, i) => (
          <ReviewStar role="img" aria-label="star" key={i}>
            ⭐
          </ReviewStar>
        ))}
        <ReviewAttribution>
          {'- '}
          {attribution}
        </ReviewAttribution>
      </ReviewStarsWrapper>
    </Review>
  );
}
