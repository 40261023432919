/*  Copyright (C) 2023 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {useLocation} from 'react-router-dom';

/** When navigating, always scroll to the top of the document */
export function useScrollToTopOnNavigate() {
  const {pathname} = useLocation();
  React.useEffect(() => {
    const scrollingEl = document.scrollingElement ?? document.documentElement;
    if (scrollingEl) scrollingEl.scrollTo({top: 0});
  }, [pathname]);
}

export const ScrollToTopOnNavigate: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
  useScrollToTopOnNavigate();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
