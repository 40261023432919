/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */

import api from 'api';
import {Highlight} from 'components/shared/styled';
import styled from 'styled-components';
import {WithComponentProps} from 'types';

const BoldHighlight = styled(Highlight)`
  font-weight: bold;
`;

export interface DeviceRequirementDisplayConfig {
  deviceRequirement: api.OhmPlus.DeviceRequirement;
}

export function DeviceRequirementDisplay({
  deviceRequirement,
  ...props
}: WithComponentProps<'span', DeviceRequirementDisplayConfig>): JSX.Element {
  return (
    <span {...props}>
      <BoldHighlight baseColor={'primary'}>{deviceRequirement.quantity}</BoldHighlight>
      {' ' +
        (deviceRequirement.quantity > 1
          ? deviceRequirement.uiLabelPlural
          : deviceRequirement.uiLabelSingular)}
    </span>
  );
}
