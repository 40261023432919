
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ActionResult": [
      "OhmResult"
    ],
    "ApplianceInventoryOpResult": [
      "FailureResult",
      "UserApplianceInventory"
    ],
    "Attribute": [
      "BooleanAttribute",
      "DateAttribute",
      "DatetimeAttribute",
      "NumericAttribute",
      "StringEnumAttribute",
      "TextAttribute"
    ],
    "AttributeBase": [
      "BooleanAttribute",
      "DateAttribute",
      "DatetimeAttribute",
      "NumericAttribute",
      "StringEnumAttribute",
      "TextAttribute"
    ],
    "AttributeFilter": [
      "BooleanAttributeFilter",
      "DateRangeAttributeFilter",
      "DateSimpleAttributeFilter",
      "DatetimeRangeAttributeFilter",
      "DatetimeSimpleAttributeFilter",
      "EnumAttributeFilter",
      "NumericRangeAttributeFilter",
      "NumericSimpleAttributeFilter",
      "TextAttributeFilter"
    ],
    "AttributeFilterSegmentResult": [
      "AttributeFilterSegment",
      "FailureResult"
    ],
    "AttributeResult": [
      "BooleanAttribute",
      "DateAttribute",
      "DatetimeAttribute",
      "FailureResult",
      "NumericAttribute",
      "StringEnumAttribute",
      "TextAttribute"
    ],
    "AuthenticationCodeResult": [
      "FailureResult",
      "Session"
    ],
    "BaseComparator": [
      "AttributeComparator",
      "FilterComparator"
    ],
    "CompoundSegmentResult": [
      "CompoundSegment",
      "FailureResult"
    ],
    "DispatchSimulatorResult": [
      "FailureResult",
      "Simulation"
    ],
    "ElectricityUsageEstimateResult": [
      "ElectricityUsageEstimate",
      "FailureResult"
    ],
    "EmailVerificationResult": [
      "EmailVerificationResponse",
      "FailureResult"
    ],
    "MaybePartnerOrFailure": [
      "FailureResult",
      "Partner"
    ],
    "MaybePartnerUserOrFailureResult": [
      "FailureResult",
      "PartnerUser"
    ],
    "MaybeUserOrFailureResult": [
      "FailureResult",
      "User"
    ],
    "MiniHomeSurveySavingsCalculatorResult": [
      "FailureResult",
      "Session"
    ],
    "OAuthOpResult": [
      "FailureResult",
      "OAuthSession"
    ],
    "PceOpInstancesResult": [
      "FailureResult",
      "PceOpSuccessWithInstances"
    ],
    "PceOpPrototypesResult": [
      "FailureResult",
      "PceOpSuccessWithPrototypes"
    ],
    "PceOpStaticSegmentedContentResult": [
      "FailureResult",
      "PceStaticSegmentedContent"
    ],
    "PhoneNumberResult": [
      "FailureResult",
      "User"
    ],
    "PhoneVerificationResult": [
      "FailureResult",
      "PhoneVerificationProgress"
    ],
    "RatePlanOptimizerFeedbackSurveyResult": [
      "FailureResult",
      "RatePlanOptimizerFeedbackSurvey"
    ],
    "RetailEnrollmentCreateUserResult": [
      "FailureResult",
      "Session"
    ],
    "RetailEnrollmentOpResult": [
      "FailureResult",
      "UserRetailEnrollmentProgress"
    ],
    "RetailEnrollmentStepResult": [
      "FailureResult",
      "MultiFailureResult",
      "User"
    ],
    "Segment": [
      "AttributeFilterSegment",
      "CodeDefinedSegment",
      "CompoundSegment"
    ],
    "SegmentBase": [
      "AttributeFilterSegment",
      "CodeDefinedSegment",
      "CompoundSegment"
    ],
    "SegmentationEngineOpResult": [
      "FailureResult",
      "SegmentationEngineOp"
    ],
    "SignUpResult": [
      "MultiFailureResult",
      "User"
    ],
    "SubmitSecondFactorCodeResult": [
      "FailureResult",
      "Session"
    ]
  }
};
      export default result;
    