/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {formatForCurrency, trackStructuredEvent} from 'utils';
import PopupAnnouncement from 'components/shared/PopupAnnouncement';
import {useFeature} from 'hooks/useFeature';
import api from 'api';
import {useGlobalState} from 'store';
import {useSetUserAttributes} from 'store/actions';
import {WithComponentPropsWithoutRef} from 'types';
import {ButtonSize, ButtonVariation} from 'components/shared/Button';
import {useHighestPayoutRateConfig} from 'store/ohmPlus';

export default function UtilityConnectionBillboard({
  ...props
}: WithComponentPropsWithoutRef<typeof PopupAnnouncement>) {
  const globalState = useGlobalState();
  const setUserAttributes = useSetUserAttributes();
  const ATTRIBUTE_KEY = 'BILLBOARD_UTILITY_CONNECTION_COLLAPSED';
  const isCollapsed: boolean = globalState?.user?.attributes[ATTRIBUTE_KEY] === '1';

  async function handleOnCollapse(isNowCollapsed: boolean) {
    if (!isNowCollapsed) {
      trackStructuredEvent({
        category: 'billboard',
        action: 'view',
        label: showClassicCopy
          ? 'utility_limbo_classic'
          : showPlusCopy
          ? 'utility_limbo_plus'
          : 'utility_limbo',
      });
    }
    await setUserAttributes({[ATTRIBUTE_KEY]: isNowCollapsed ? '1' : '0'});
  }

  const showClassicCopy = useFeature(
    api.Features.Tree.Onboarding.BillboardAnnouncement.UtilityConnection.OhmClassicCopy,
  );
  const showPlusCopy = useFeature(
    api.Features.Tree.Onboarding.BillboardAnnouncement.UtilityConnection.OhmPlusCopy,
  );

  const highestPayoutRateConfig = useHighestPayoutRateConfig();
  const maxPlusRate = highestPayoutRateConfig
    ? formatForCurrency(
        highestPayoutRateConfig.payout.value,
        highestPayoutRateConfig.payout.currencyType,
        {
          notation: 'compact',
        },
      )
    : undefined;

  return (
    <>
      {showClassicCopy && !showPlusCopy ? (
        <PopupAnnouncement
          {...props}
          options={{
            header: 'Finish your account setup',
            message: (
              <p>
                <span role="img" aria-label="">
                  🔐
                </span>{' '}
                Unlock energy events and rewards by connecting your utility account.
              </p>
            ),
            includeIcon: false,
            dismissable: false,
            collapsable: true,
            isCollapsed: isCollapsed,
            collapseLabel: 'Hide',
            uncollapseLabel: 'Finish your account setup',
            onCollapse: handleOnCollapse,
            align: 'center',
            ctas: [
              {
                to: '/signup/utility?edit=true',
                confirmTrackId: 'pre_authdone_finish_setup_cta_click',
                onConfirm: () =>
                  trackStructuredEvent({
                    category: 'billboard',
                    action: 'click',
                    label: 'utility_limbo_classic_connect_utility',
                  }),
              },
            ],
          }}
        />
      ) : null}
      {showPlusCopy && !showClassicCopy ? (
        <PopupAnnouncement
          {...props}
          options={{
            header:
              `Get started on earning ` +
              (maxPlusRate ? `up to ${maxPlusRate} ` : '') +
              `every month by connecting your utility`,
            message: (
              <p>
                It's safe, secure
                <br />
                and only takes 2 minutes
              </p>
            ),
            includeIcon: false,
            dismissable: false,
            collapsable: true,
            isCollapsed: isCollapsed,
            collapseLabel: 'Hide',
            uncollapseLabel: 'Connect Your Utility',
            onCollapse: handleOnCollapse,
            align: 'center',
            ctas: [
              {
                to: '/signup/utility?edit=true',
                confirmTrackId: 'pre_authdone_finish_setup_cta_click',
                onConfirm: () =>
                  trackStructuredEvent({
                    category: 'billboard',
                    action: 'click',
                    label: 'utility_limbo_plus_connect_utility',
                  }),
                content: 'Connect Your Utility',
                variation: ButtonVariation.PRIMARY,
                size: ButtonSize.STANDARD,
              },
            ],
          }}
        />
      ) : null}
    </>
  );
}
