/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */

import api from 'api';
import styled, {css} from 'styled-components';
import {WithComponentProps} from 'types';
import {JoinItems} from 'utils';
import {DeviceRequirementDisplay} from './DeviceRequirementDisplay';
import {PayoutDisplay} from './PayoutDisplay';

const Wrapper = styled.div`
  ${({theme}) => css`
    border-radius: ${theme.standard.borderRadiusLg};
    background-color: ${theme.content.background};
    font-size: 13px;
  `}
`;

const TABLE_HORIZONTAL_CELL_GAP = 8;
const TABLE_VERTICAL_CELL_GAP = 4;

const StyledTable = styled.table<{tablePadding: number}>`
  ${({theme, tablePadding}) => css`
    border-collapse: collapse;
    width: 100%;
    text-align: left;

    /* all cells */
    th,
    td {
      padding: ${TABLE_VERTICAL_CELL_GAP}px 0 ${TABLE_VERTICAL_CELL_GAP}px
        ${TABLE_HORIZONTAL_CELL_GAP}px;
      /* first column */
      &:nth-child(1) {
        padding-left: ${tablePadding}px;
      }
      /* last column */
      &:nth-last-child(1) {
        padding-right: ${tablePadding}px;
        text-align: right;
      }
    }

    /* header cells */
    th {
      white-space: nowrap;
      border-bottom: 1px solid ${theme.color.primary50};
      padding: ${tablePadding}px 0 ${TABLE_VERTICAL_CELL_GAP}px;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: ${theme.text.transform};
    }

    /* first-row cells */
    tr:nth-child(1) td {
      /* gap between header border and content */
      padding-top: ${Math.max(tablePadding, TABLE_VERTICAL_CELL_GAP)}px;
    }

    /* last-row cells */
    tr:nth-last-child(1) td {
      padding-bottom: ${tablePadding}px;
    }
  `}
`;

const StyledDeviceRequirementWrapper = styled.span`
  display: inline-block;
  padding: 2px 0;
`;

const StyledRequirementSeparator = styled.span`
  /** */
`;

export interface PayoutTableConfig {
  rateConfigs: api.OhmPlus.RateConfig[];
  padding?: number;
}

export function PayoutTable({
  rateConfigs,
  padding: tablePadding = 12,
  ...props
}: WithComponentProps<'div', PayoutTableConfig>): JSX.Element {
  // sort descending by payout value
  const sortedRateConfigs = rateConfigs.sort((a, b) => b.payout.value - a.payout.value);

  return (
    <Wrapper {...props}>
      <StyledTable {...{tablePadding}}>
        <thead>
          <tr>
            <th>Link These</th>
            <th>And Earn</th>
          </tr>
        </thead>
        <tbody>
          {sortedRateConfigs.map((c, idx) => (
            <tr key={'rateConfigRow::' + c.id}>
              <td>
                {JoinItems({
                  items: c.deviceRequirements.map(requirement => (
                    <StyledDeviceRequirementWrapper>
                      <DeviceRequirementDisplay
                        key={c.id + '::' + requirement.id}
                        deviceRequirement={requirement}
                      />
                    </StyledDeviceRequirementWrapper>
                  )),
                  separator: <StyledRequirementSeparator>{' + '}</StyledRequirementSeparator>,
                })}
              </td>
              <td>
                <PayoutDisplay payout={c.payout} suffix="/mo" />
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </Wrapper>
  );
}
