/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import {useCallback} from 'react';
import {get, orderBy, sortBy} from 'lodash';

import api from 'api';
import {useGlobalState} from 'store';
import {EventPhase, getEventPhase} from 'store/events';
import {isOhmHour} from 'utils';

export function useGetUpcomingEventById(): (eventId: number) => api.Events.UpcomingEvent | null {
  const globalState = useGlobalState();
  return useCallback(
    (eventId: number) =>
      globalState.upcomingEvents
        ? globalState.upcomingEvents.find(e => e.f_ohm_hour_user_id === eventId) || null
        : null,
    [globalState.upcomingEvents],
  );
}

export function useUpcomingEvents(): api.Events.UpcomingEvent[] {
  const globalState = useGlobalState();
  return globalState.upcomingEvents ?? [];
}

export function useGetSuccessfulEvents() {
  const globalState = useGlobalState();
  const energyEvents = get(globalState, 'energyEvents', []);
  return useCallback(
    () =>
      energyEvents
        ? energyEvents.filter(
            (event: api.Events.EventData) =>
              getEventPhase(event) === EventPhase.POST_EVENT &&
              event.reduction_results_available &&
              !event.forgiveness_applied &&
              event.actual_kwh < event.baseline_kwh,
          )
        : [],
    [energyEvents],
  );
}

export function useGetIsLatestSuccessfulEvent() {
  const getSuccessfulEvents = useGetSuccessfulEvents();
  const successfulEvents = getSuccessfulEvents();

  return useCallback(
    (eventId: number) =>
      get(orderBy(successfulEvents, 'start_dttm', ['desc']), '[0].f_ohm_hour_user_id') === eventId,
    [successfulEvents],
  );
}

export function useGetParticipatingOhmHours() {
  const globalState = useGlobalState();
  const energyEvents = get(globalState, 'energyEvents', []);

  return useCallback(
    () => energyEvents?.filter(event => isOhmHour(event.ohmhour_type) && event.participating),
    [energyEvents],
  );
}

export function useGetIsFirstParticipatingOhmHour() {
  const getParticipatingOhmHours = useGetParticipatingOhmHours();
  const participatingOhmHours = getParticipatingOhmHours();

  return useCallback(
    (eventId: number) =>
      get(sortBy(participatingOhmHours, 'start_dttm'), '[0].f_ohm_hour_user_id') === eventId,
    [participatingOhmHours],
  );
}
