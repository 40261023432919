/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig, Oem, LoginMethod, SnowPlowUrl} from '../env.types';
import {ORG_SUBSCRIPTIONS} from 'config/subscriptions';
import {Country} from 'config/regions';

const BACKEND_URL = 'https://spike.originenergy.com.au';

export const ORG_PRODUCTION_ENV: EnvConfig = {
  oem: Oem.ORIGIN,
  nodeEnv: NodeEnv.PRODUCTION,
  name: 'Origin Production',
  backendBaseUrl: BACKEND_URL,
  baseDocumentTitle: 'Origin Spike',
  country: Country.AU,

  defaultLoginMethod: LoginMethod.SSO,
  // Login method for Guest users (Admins/testers)
  loginUrl: '/login',
  // Login method for Origin users (regular customers)
  ssoLoginUrl: `${BACKEND_URL}/connect/origin`,

  // Used in the webpage to configure events/program names
  oemName: 'Spike',
  reductionEvent: 'SpikeHour',
  realtimeEvent: 'AutoSpike',

  // OCE is Texas only
  oceEnrollmentUrl: '',
  // FriendBuy is a referral platform we use for ohm
  friendBuyMerchantId: '',
  // Note: Key below is copied from backend config.
  googleRecaptchaSiteKey: '6LeaRuQUAAAAAKExB1eMoQqQaOpPJD0v77xXUmSL',

  // Link to go "back" to an external utility account dashboard from our dashboard
  dashboardAccountLink: 'https://www.originenergy.com.au/my',

  // Note: Key below is copied from backend config.
  // todo: Provide valid key accordingly.
  // (key here id for testing and will always result in success.)
  hcaptchaSiteKey: '2c7cc792-bcb3-42f2-8bf6-88c2102d1c6f',
  facebookAppId: '',
  gmailClientId: '145025664631-3t6etib0eu2jef8j699oclm9s4vs1glg.apps.googleusercontent.com',
  //
  facebookPageUrl: 'https://www.facebook.com/OriginEnergy',
  instagramPageUrl: 'https://www.instagram.com/originenergy',
  twitterPageUrl: 'https://twitter.com/originenergy',
  // We do not use fullstory for Origin, however it is required for Origin.
  // @TODO: Setup Origin FullStory?
  fullstoryOrganizationId: '11NDHN',
  //
  askShortUrl: 'https://www.originenergy.com.au/help-support/earn-rewards/spike/',
  helpShortUrl: 'https://www.originenergy.com.au/help-support/earn-rewards/spike/',
  helpFormUrl: 'https://ohmaccounts.typeform.com/to/PQbAe9bg',
  helpEmail: 'help@spike.originenergy.com.au',
  privacyPolicyUrl: 'https://www.originenergy.com.au/privacy/',
  termsUrl:
    'https://www.originenergy.com.au/help-support/earn-rewards/spike/spike-terms-and-conditions',
  prizeRulesUrl:
    // eslint-disable-next-line max-len
    'https://www.originenergy.com.au/help-support/earn-rewards/spike/spike-spin-to-win-terms-conditions-and-winners',

  // Origin promos and device offers TBD ON-5454
  shopifyBundlesVariantIds: [],
  //
  cookieTargetingAllowedId: 'C0004',
  // cookie domain needs a `.` infront so it works for all subdomains
  cookieDomain: '.originenergy.com.au',
  // We do not use google RHR for Origin
  googleRhrEnrollUrl: '',
  subscriptionList: ORG_SUBSCRIPTIONS,
  snowplowCollectorUrl: SnowPlowUrl.ORG,
  wattConversionRate: 250,

  emsAppUrl: '/',
};
