/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import styled, {css} from 'styled-components';

export const ReviewTestimonialsWrapper = styled.div`
  ${({theme}) => css`
    background-color: ${theme.color.primary100};
    padding: 16px;
    display: flex;
    flex: auto;
    align-items: center;
    flex-direction: column;
    width: 100%;
  `}
`;
