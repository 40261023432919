/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import api from 'api';
import {useGlobalState} from 'store';
import {FailedEventsCounts} from './ohmPlus.types';
import {useUpcomingEvents} from 'store/events';

/**
 * @deprecated no more ohm plus
 * Looks at all of the possible rate config options, and returns the one
 * with the highest payout
 * @returns The highest rate config option
 */
export function useHighestPayoutRateConfig(): api.OhmPlus.RateConfig | undefined {
  const globalState = useGlobalState();

  if (globalState?.plus?.rateConfigs?.length) {
    const maxPlusRate = globalState.plus.rateConfigs.reduce((maxPayoutRateConfig, config) => {
      if (maxPayoutRateConfig?.payout?.value >= config.payout.value) {
        return maxPayoutRateConfig;
      } else {
        return config;
      }
    }, {} as api.OhmPlus.RateConfig);
    return maxPlusRate;
  } else {
    return undefined;
  }
}
/**
 * @deprecated no more ohm plus
 * An object with counts for the number of total strikes, currently used strikes,
 * expected upcoming failures, and remaining strikes.
 * @returns Strike status object
 */
export function useFailedEventsCounts(): FailedEventsCounts {
  const allowance = useAllowanceFailedEventsCount();
  const current = useCurrentFailedEventCount();
  const remaining = useRemainingFailedEventCount();
  const upcoming = useUpcomingFailedEvents().length;

  return {allowance, current, remaining, upcoming};
}

/**
 * @deprecated no more ohm plus
 * The total allowance of strikes allowed
 * @returns Count of total allowed strikes
 */
export function useAllowanceFailedEventsCount(): number {
  // TODO: get this from the API
  return 3;
}

/**
 * @deprecated no more ohm plus
 * The current number of strikes used
 * @returns Count of strikes already used
 */
export function useCurrentFailedEventCount(): number {
  const globalState = useGlobalState();
  const upcomingFailedEvents = useUpcomingFailedEvents();
  const currentFailedEvents = globalState.plus?.status?.currentFailedEvents;

  const upcomingOptOutEventsCount = upcomingFailedEvents.filter(
    e => e.ohm_plus_event_summary?.failureReason === api.OhmPlus.FailureReason.EVENT_OPT_OUT,
  ).length;
  const currentFailedEventsCount = !!currentFailedEvents
    ? currentFailedEvents.filter(strike => !strike.isForgiven && strike.isFailure).length
    : 0;

  return currentFailedEventsCount + upcomingOptOutEventsCount;
}

/**
 * @deprecated no more ohm plus
 * The remaining strikes available to be used
 * @returns Count of remaining strikes
 */
export function useRemainingFailedEventCount(): number {
  const allowanceFailedEventsCount = useAllowanceFailedEventsCount();
  const currentFailedEventsCount = useCurrentFailedEventCount();
  return Math.max(allowanceFailedEventsCount - currentFailedEventsCount, 0);
}

/**
 * @deprecated no more ohm plus
 * get a list of upcoming events expected to be a failure
 * @returns list of upcoming events which are expected to be a failure
 */
export function useUpcomingFailedEvents() {
  const upcomingEvents = useUpcomingEvents();
  return upcomingEvents.filter(e => e.ohm_plus_event_summary?.isFailure);
}

/**
 * Always false now that plus is disabled
 * @deprecated No more ohm plus
 * @param isoDateTime time string to check against current period
 * @returns boolean
 */
export function useGetIsTimeInCurrentPeriod(isoDateTime?: string | null): boolean {
  return false;
}

/**
 * The users ohm plus status, if set
 * @deprecated no more ohm plus
 */
export function usePlusStatus(): api.OhmPlus.StatusData | undefined {
  const globalState = useGlobalState();
  return globalState.plus?.status;
}

/**
 * @deprecated no more ohm plus
 * the user's current payout, if available
 */
export function usePlusCurrentPayout() {
  const plus = usePlusStatus();
  return plus?.currentPayout;
}

export function usePlusPayoutInterval() {
  const plus = usePlusStatus();
  return plus?.payoutInterval;
}

/**
 * false now because nobody is in plus
 * @deprecated no more ohm plus
 */
export function useIsOhmPlus() {
  return false;
}
