/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
/* eslint-disable camelcase */
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Expand} from 'types';
import {OhmPlus} from './ohmPlus.api';
import {Connections} from './connections.api';

export namespace Events {
  /**********
   * Interactions
   **********/

  /**
   * Get upcoming events
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getUpcomingEvents<TResponse = UpcomingEventsData, TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/upcoming_events`, config);
  }

  /**
   * Get the last 20 combined events a user participated in
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getEvents<TResponse = EventData[], TConfigData = never>(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/events`, config);
  }

  /**
   * Get an event by f_ohm_hour_user_id
   * @param id f_ohm_hour_user_id of the event to get
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getEventById<TResponse = EventData, TConfigData = never>(
    id: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/events/${id}`, config);
  }

  /**
   * Get an event baseline records by f_ohm_hour_user_id
   * @param id f_ohm_hour_user_id of the event to get records for
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getEventBaselineById<TResponse = EventBaselineData, TConfigData = never>(
    id: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/events/${id}/baseline_records`, config);
  }

  /**
   * Get a count the users historical events
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getHistoricalEventsCount<
    TResponse = HistoricalEventsCountData,
    TConfigData = never
  >(
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/historical_events_count`, config);
  }

  /**
   * Get an event device participation by f_ohm_hour_user_id
   * @param id f_ohm_hour_user_id of the event
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function getEventDeviceParticipationById<
    TResponse = EventDeviceParticipationData,
    TConfigData = never
  >(
    id: number,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.get(`/api/v2/event_device_participation/${id}`, config);
  }

  /**
   * Opt a device in or out of an event
   * @param data Data to pass in
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function eventDeviceOptInOut<
    TResponse = EventDeviceOptInOutConfig,
    TConfigData = EventDeviceOptInOutData
  >(
    data?: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/event_device_opt_in_out`, data, config);
  }

  /**
   * Opt in or out of an entire event
   * @param data Data to pass in
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function eventOptInOut<
    TResponse = EventOptInOutConfig,
    TConfigData = EventOptInOutData
  >(
    data?: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/event_opt_in_out`, data, config);
  }

  /**
   * Opt in or out of an entire event, endpoint unauthenticated by design.
   * Made for frontend/src/components/OptInUser/OptInUser.tsx
   * @param data Data to pass in
   * @param config Optional request config
   * @returns Promise of response
   * @template TResponse Type of response data
   * @template TConfigData Type of config data passed in
   */
  export async function userEventOptIn<
    TResponse = UserEventOptInConfig,
    TConfigData = UserEventOptInData
  >(
    data?: TConfigData,
    config?: AxiosRequestConfig<TConfigData>,
  ): Promise<AxiosResponse<Expand<TResponse>, TConfigData>> {
    return await axios.post(`/api/v2/event_opt_in_user`, data, config);
  }

  /**********
   * Types
   **********/

  /**
   *
   */
  export interface UpcomingEvent {
    start_dttm: string;
    end_dttm: string;
    end_dttm_may_change: boolean;
    ohmhour_type: string; // TODO: make an ENUM
    ohm_hour_id: number;
    f_ohm_hour_user_id: number;
    has_solar: boolean;
    participating: boolean;
    forgiveness_applied: boolean;
    baseline_records: null | unknown; // TODO: type this
    baseline_kwh: null | unknown; // TODO: type this
    potential_breakdown: EventBreakdown;
    bonus_type: null | unknown; // TODO: make an ENUM
    bonus_value: null | unknown;
    devices: Record<number, DeviceToggleData>;
    is_iso_weekday: boolean;
    algorithm_name: Algorithm;

    /** the prediction of ohm plus information for the event */
    ohm_plus_event_summary: OhmPlus.PreEventSummary;
  }

  /**
   *
   */
  export interface DeviceToggleData {
    advance_dttm: string;
    advance_state: string; // TODO: type this
    advance_summary: string; // TODO: type this
    device_id: number;
    during_dttm: string;
    during_state: string; // TODO: type this
    participation_state: Connections.EventDeviceParticipationState;
    points: null | number;
    potential: number;
    turn_off_dttm: string;
    turn_off_state: string; // TODO: type this
    turn_off_summary: string; // TODO: type this
    turn_on_dttm: string;
    turn_on_state: string; // TODO: type this
    turn_on_summary: string; // TODO: type this
  }

  /**
   *
   */
  export interface EventBreakdown {
    bonus: number;
    device: number;
    energy: number;
    total: number;
  }
  /**
   *
   */
  export interface UpcomingEventsData extends Record<string | number, any> {
    // TODO: type this
  }

  /**
   *
   */
  export interface EventData extends Record<number, any> {
    start_dttm: string;
    end_dttm: string;
    participating: boolean;
    forgiveness_applied: boolean;
    ohmhour_type: string;
    f_ohm_hour_user_id: number;
    reduction_results_available: boolean;
    actual_kwh: number;
    baseline_kwh: number;
    potential_breakdown: EventBreakdown;
    reduction_points: number;
    awarded_wheel_spins: number;
    awarded_prize_entries: number;
    algorithm_name: Algorithm;
    points: number;
    participation_state: string;
    // TODO: type this
    /**
     * a summary for OhmPlus specific data for the event, can be null if:
     *  - the event occured while the user was not in OhmPlus
     *  - the event has not been processed yet by OhmPlus logic
     *    (highly unlikely more than 15min post event)
     */
    ohm_plus_event_summary: OhmPlus.EventSummary | null;
    devices: Record<string | number, any>[]; // TODO: type this
  }

  /**
   *
   */
  export interface EventBaselineData extends Record<string | number, any> {
    // TODO: type this
  }

  /**
   *
   */
  export interface HistoricalEventsCountData {
    HistoricalEventsCount: number;
  }

  /**
   *
   */
  export interface EventDeviceParticipationData extends Record<string | number, any> {
    // TODO: type this
  }

  /**
   *
   */
  export interface EventDeviceOptInOutConfig {
    ohm_hour_id: number;
    device_id: number;
    in_out: OptInOut;
  }
  /**
   *
   */
  export interface EventDeviceOptInOutData extends Record<string | number, any> {
    // TODO: type this
  }

  /**
   *
   */
  export interface EventOptInOutConfig {
    f_ohm_hour_user_id: number;
    in_out: OptInOut;
  }
  /**
   *
   */
  export interface EventOptInOutData extends Record<string | number, any> {
    // TODO: type this
  }

  export interface UserEventOptInConfig {
    eventId: number;
    startDttm: string;
    endDttm: string;
    rewardId: number;
  }

  export interface UserEventOptInData extends Record<string | number, any> {
    user_hex_hash: string;
  }

  /**
   *
   */
  export enum OptInOut {
    IN = 'in',
    OUT = 'out',
  }

  export enum Algorithm {
    // Classic OhmHours
    PARTICIPATION_REDUCTION = 'participation_reduction',
    // Classic AutoOhms
    PARTICIPATION_REDUCTION_RT = 'participation_reduction_rt',
    // Plus OhmHours
    REDUCTION = 'reduction',
    // Plus AutoOhms
    REDUCTION_RT = 'reduction_rt',
    // Behavioral AutoOhms
    PARTICIPATION_REDUCTION_RT_BREAK_STREAKS = 'participation_reduction_rt_break_streaks',
    // Perks
    DEVICE_PARTICIPATION = 'device_participation',
    // Origin SpikeHours
    PARTICIPATION_REDUCTION_ORG = 'participation_reduction_org',
    // Origin AutoSpikes
    PARTICIPATION_REDUCTION_ORG_RT = 'participation_reduction_org_rt',
  }
}
