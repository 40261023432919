/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import React, {useEffect} from 'react';
import {get, isEmpty} from 'lodash';
import {useGlobalState} from 'store';
import PopupAnnouncement from 'components/shared/PopupAnnouncement';
import {environmentEquals, Oem} from 'config/environments';
import {useGetUserUtilityNeverConnected} from 'store/selectors';
import {useExperimentExposed} from 'hooks/useExperiment';
import {useLocation} from 'react-router-dom';
import {WithComponentPropsWithoutRef} from 'types';
import {useFeature} from 'hooks/useFeature';
import api from 'api';
import UtilityConnectionBillboard from './UtilityConnectionBillboard';
import DeviceConnectionBillboard from './DeviceConnectionBillboard';
import DevicePayoutTableBillboard from './DevicePayoutTableBillboard';
import {useSetBillboardAnnouncementDisplay} from 'store/actions';

export default function BillboardAnnouncement({
  ...props
}: WithComponentPropsWithoutRef<typeof PopupAnnouncement>) {
  const globalState = useGlobalState();
  const location = useLocation();
  const experimentExposed = useExperimentExposed();
  const getUserUtilityNeverConnected = useGetUserUtilityNeverConnected();
  const utilityNeverConnected = getUserUtilityNeverConnected();
  const setBillboardAnnouncementDisplay = useSetBillboardAnnouncementDisplay();

  const userIsAuthenticated = !!globalState.user.id;

  const isBlockedByPath =
    location.pathname.startsWith('/signup') ||
    location.pathname.startsWith('/login') ||
    location.pathname.startsWith('/new-user') ||
    location.pathname.startsWith('/device_offer') ||
    location.pathname.startsWith('/promo') ||
    location.pathname.startsWith('/stripe') ||
    location.pathname.startsWith('/coupons') ||
    location.pathname.startsWith('/update-google-nest') ||
    location.pathname.startsWith('/invite') ||
    location.pathname.startsWith('/settings') ||
    location.pathname.startsWith('/wallet');

  const suppressUtilityConnectionBillboard = useFeature(
    api.Features.Tree.Onboarding.BillboardAnnouncement.UtilityConnection.Suppress,
  );

  // show to non-utility connected users
  const showUtilityConnectionBillboard =
    useFeature(api.Features.Tree.Onboarding.BillboardAnnouncement.UtilityConnection.View) &&
    !suppressUtilityConnectionBillboard &&
    userIsAuthenticated &&
    experimentExposed('spa_full') &&
    !environmentEquals(Oem.ORIGIN) &&
    utilityNeverConnected;

  const userLatestOrderStatus = get(globalState, 'orders.userOrderStatus.latest_order_status');
  const connectionDevices = get(globalState, 'connectionDevices');
  const noDevices = !!connectionDevices && isEmpty(connectionDevices);

  // show to users with no devices and no devices on order, except on the devices pages
  const showDeviceConnectionBillboard =
    useFeature(api.Features.Tree.Onboarding.BillboardAnnouncement.DeviceConnection.View) &&
    !location.pathname.startsWith('/devices') &&
    noDevices &&
    !userLatestOrderStatus;

  // show to plus users, only on the devices pages
  const showDevicePayoutTableBillboard =
    useFeature(api.Features.Tree.Devices.BillboardAnnouncement.DevicePayoutTable.View) &&
    (location.pathname === '/devices' || location.pathname === '/devices/add');

  useEffect(() => {
    const initialDisplay =
      showUtilityConnectionBillboard ||
      showDeviceConnectionBillboard ||
      showDevicePayoutTableBillboard;

    setBillboardAnnouncementDisplay({
      isVisible: initialDisplay,
    });
    // clean up on unmount
    return () => {
      setBillboardAnnouncementDisplay({
        isVisible: false,
      });
    };
  }, [
    setBillboardAnnouncementDisplay,
    showUtilityConnectionBillboard,
    showDeviceConnectionBillboard,
    showDevicePayoutTableBillboard,
  ]);

  // Only one billboard shown at a time, this is a prioritized switch
  if (isBlockedByPath) {
    return null;
  } else if (showUtilityConnectionBillboard) {
    return <UtilityConnectionBillboard {...props} />;
  } else if (showDeviceConnectionBillboard) {
    return <DeviceConnectionBillboard {...props} />;
  } else if (showDevicePayoutTableBillboard) {
    return <DevicePayoutTableBillboard {...props} />;
  } else return null;
}
