/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */

import api from 'api';
import {Highlight} from 'components/shared/styled';
import styled from 'styled-components';
import {WithComponentProps} from 'types';
import {formatForCurrency} from 'utils';

const BoldHighlight = styled(Highlight)`
  font-weight: bold;
`;

export interface PayoutDisplayConfig {
  payout: api.OhmPlus.Payout;
  fullNotation?: boolean;
  suffix?: JSX.Element | string | null;
}

export function PayoutDisplay({
  payout,
  fullNotation = false,
  suffix = '',
  ...props
}: WithComponentProps<'span', PayoutDisplayConfig>): JSX.Element {
  return (
    <BoldHighlight {...props} baseColor={'success'}>
      {formatForCurrency(payout.value, payout.currencyType, {
        notation: fullNotation ? 'standard' : 'compact',
      })}
      {suffix}
    </BoldHighlight>
  );
}
