/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import React, {useState} from 'react';
import {useGlobalState} from 'store';
import styled, {css} from 'styled-components';

import {useExperimentVariant} from 'hooks/useExperiment';
import {useExperimentExposed} from 'hooks/useExperiment';
import {PayoutTable} from 'components/DeviceRequirements';
import {Button} from 'components/shared/Button';
import {LargeHeader, SmallHeader, TermsText} from 'components/shared/styled';

import {DEVICE_OFFER_CONTENT_VARIANT_A, DEVICE_OFFER_CONTENT_VARIANT_B} from './DeviceOffer.config';
import {ReviewTestimonial, ReviewTestimonialsWrapper} from 'components/shared/ReviewTestimonial';
import {MainLayout} from '../shared/layouts';
import {TopBrandBarBackButton} from '../shared/TopBrandBarBackButton';
import {useSetBrandBarDisplay} from '../../store/brandBarDisplay';

const PageHeaderWrapper = styled.div`
  text-align: center;
`;

const PageWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const PageContentWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: auto;
  align-items: center;
  max-width: 500px;
  gap: 16px;
`;

const CtaButton = styled(Button)`
  max-height: 44px;
`;

const ItemWrapper = styled.div`
  ${({theme}) => css`
    border-radius: ${theme.standard.borderRadiusLg};
    background: ${theme.content.background};
    padding: 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
  `}
`;

const ItemContent = styled.div`
  flex: auto;
`;

const ItemImage = styled.img`
  width: 100px;
  height: 100px;
`;

const ItemPriceChip = styled.div`
  ${({theme}) => css`
    padding: 4px 8px;
    border: 1px solid ${theme.color.primary100};
    border-radius: ${theme.standard.borderRadius};
    display: inline-block;
    margin-top: 12px;
    font-weight: bold;
    color: ${theme.color.primary};
  `}
`;

const ItemOriginalPrice = styled.span`
  ${({theme}) => css`
    margin-left: 8px;
    color: ${theme.text.color.tertiary};
    font-weight: normal;
    text-decoration: line-through;
  `}
`;

const FooterWrapper = styled.div`
  margin-bottom: 16px;
`;

export function DeviceOffer() {
  const globalState = useGlobalState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const rateConfigs = globalState?.plus?.rateConfigs ?? [];

  const experimentVariant = useExperimentVariant();
  const experimentExposed = useExperimentExposed();
  const variantB = experimentVariant('device_offer_plus_shopify_ohm_plug') === 'b_variant';

  const offer = variantB ? DEVICE_OFFER_CONTENT_VARIANT_B : DEVICE_OFFER_CONTENT_VARIANT_A;

  // Temporary experiment to get between OhmPlug V1 and V2 images
  const deviceImageUrl = experimentExposed('ohm_plus_device_offer_ohmplug_v2')
    ? 'images/offers/ohmPlug_static_offer_image_v2.png'
    : offer.item.imageUrl;

  useSetBrandBarDisplay({
    leftContent: (
      <TopBrandBarBackButton
        to="/"
        onClick={() => {
          // TODO: how do we want to track this click?
          // trackNbaEvent(offerContent, 'click', 'signup', 'back_to_offer_options_btn');
        }}
      />
    ),
  });

  return (
    <MainLayout>
      <PageWrapper>
        <PageContentWrapper>
          <PageHeaderWrapper>
            {offer.title ? <LargeHeader>{offer.title}</LargeHeader> : null}
          </PageHeaderWrapper>
          <ItemWrapper>
            {deviceImageUrl ? <ItemImage src={deviceImageUrl} /> : null}
            <ItemContent>
              <div>
                {offer.item.label ? <SmallHeader>{offer.item.label}</SmallHeader> : null}
                {offer.item.description ? offer.item.description : null}
              </div>
              {offer.item.price ? (
                <ItemPriceChip>
                  {offer.item.price}
                  {offer.item.originalPrice ? (
                    <ItemOriginalPrice>{offer.item.originalPrice}</ItemOriginalPrice>
                  ) : null}
                </ItemPriceChip>
              ) : null}
            </ItemContent>
          </ItemWrapper>
          {offer.ctas.map((cta, idx) =>
            cta.url || cta.to ? (
              <CtaButton
                key={'device-offer-cta-' + idx}
                variation={cta.variation}
                size={cta.size}
                onClick={() => {
                  setButtonLoading(true);
                  return cta.onConfirm();
                }}
                href={cta.to ? undefined : cta.url}
                to={!cta.to ? undefined : cta.to}
                target={cta.target}
                disabled={buttonLoading}
                loading={buttonLoading}
              >
                {cta.content}
              </CtaButton>
            ) : null,
          )}
          {offer.finePrint ? <TermsText>{offer.finePrint}</TermsText> : null}
          {rateConfigs.length && offer.showPayoutTable ? <PayoutTable {...{rateConfigs}} /> : null}
        </PageContentWrapper>
        {offer.reviews ? (
          <ReviewTestimonialsWrapper>
            {offer.reviews.map((review, ctx) => (
              <ReviewTestimonial {...review} />
            ))}
          </ReviewTestimonialsWrapper>
        ) : null}
        {offer.footer ? <FooterWrapper>{offer.footer}</FooterWrapper> : null}
      </PageWrapper>
    </MainLayout>
  );
}
