/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */

export interface JoinItemsConfig {
  /** Array of elements or strings to join together */
  items: (JSX.Element | string | null)[];
  /** A the separator to join the items */
  separator?: JSX.Element | string | null;
  /** The separator to show between the last two items */
  lastSeparator?: JSX.Element | string | null;
}

/**
 * Joins an array of Elements or strings with the provided `separator` and `lastSeparator`.
 * Returns an Element representing the join. The separators can also be an Element or string.
 * @returns JSX.Element
 */
export function JoinItems({
  items,
  separator = ', ',
  lastSeparator = separator,
}: JoinItemsConfig): JSX.Element | null {
  return (
    <>
      {items
        // turn all items into elements, in case any are strings
        .map(item => <>{item}</>)
        .reduce(
          (result, item, idx, arr) => (
            <>
              {arr.length > 1 && idx === arr.length - 1 ? (
                // If there's more than 1 item, and this is the last item, show last sepatator
                <>
                  {result}
                  {lastSeparator}
                </>
              ) : idx > 0 ? (
                // If this isn't the first item, show the regular separator
                <>
                  {result}
                  {separator}
                </>
              ) : null}
              {/* Then append this item */}
              {item}
            </>
          ),
          // Default to null, which would return if an empty array of items is supplied
          <>{null}</>,
        )}
    </>
  );
}
