/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import React from 'react';
import {get} from 'lodash';
import styled, {css} from 'styled-components';

import useTimeout from 'hooks/useTimeout';
import {captureException} from 'utils';
import {WithComponentPropsWithoutRef} from 'types';

// In seconds
const DEFAULT_TIMEOUT = 15;

const SIZES = {
  small: '24px',
  medium: '50px',
  large: '100px',
};

const StyledLoader = styled.div<Pick<LoadingSpinnerCustomProps, 'size' | 'verticalMargin'>>`
  ${({theme, verticalMargin, size}) => css`
    position: relative;
    margin: ${verticalMargin || '40px'} auto;
    width: ${get(SIZES, size, SIZES.large)};

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
      }
    }

    @keyframes color {
      100%,
      0%,
      40%,
      66%,
      80%,
      90% {
        stroke: ${theme.color.grey200};
        stroke-opacity: 0.7;
      }
    }
  `}
`;

const StyledSvg = styled.svg`
  animation: rotate 1.5s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const StyledCircle = styled.circle`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
`;

/** Custom props passed into the loading spinner component */
interface LoadingSpinnerCustomProps {
  id: string;
  size: string;
  timeout: number;
  verticalMargin?: string;
}

/**
 * Loading spinner component
 */
export default function LoadingSpinner(
  props: WithComponentPropsWithoutRef<
    'div',
    Partial<LoadingSpinnerCustomProps> & Pick<LoadingSpinnerCustomProps, 'id'>
  >,
) {
  const {id, size = SIZES.large, timeout = DEFAULT_TIMEOUT, verticalMargin, ...restProps} = props;
  useTimeout(() => {
    captureException(`Loading spinner timed out. ID: ${id}`);
  }, timeout * 1000);

  return (
    <StyledLoader
      role={'progressbar'}
      className={restProps.className}
      verticalMargin={verticalMargin}
      size={size}
    >
      <StyledSvg viewBox="25 25 50 50">
        <StyledCircle cx="50" cy="50" r="20" fill="none" strokeWidth="5" strokeMiterlimit="10" />
      </StyledSvg>
    </StyledLoader>
  );
}
