/*  Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved  */
import {ReviewTestimonialProps} from './ReviewTestimonial';

export const AliePlugReview: ReviewTestimonialProps = {
  review:
    // eslint-disable-next-line max-len
    'This plug is so easy to set up and use. I have already ordered another one for my energy hog refrigerator.',
  stars: 5,
  attribution: 'Alie',
};

export const RuthOhmReview: ReviewTestimonialProps = {
  review:
    // eslint-disable-next-line max-len
    "Easy to set up and link with OhmConnect. Every OhmHour I'm making more and helping the environment. A real win-win for everyone.",
  stars: 5,
  attribution: 'Ruth',
};

export const CandaceOhmReview: ReviewTestimonialProps = {
  review:
    // eslint-disable-next-line max-len
    'I love OhmConnect! I am saving money on energy as well as earning gift cards, entering to win bigger prizes and do something good for the environment. It is so easy to do!',
  stars: 5,
  attribution: 'Candace',
};
export const CarlOhmReview: ReviewTestimonialProps = {
  review:
    // eslint-disable-next-line max-len
    'OhmConnect has made conserving energy easy, fun and rewarding. Thank you OhmConnect!',
  stars: 5,
  attribution: 'Carl',
};
